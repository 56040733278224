import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Services from '@material-ui/icons/RoomService';
import Locations from '@material-ui/icons/LocationCity';
import Education from '@material-ui/icons/School'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionLetsTalkHealth({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 1 START */}
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Let's Talk Health</h2>
              <h5 className={classes.description}>
                At Cardiovascular Care, our dedicated team is passionate about your well-being. 
                We offer a comprehensive range of services across multiple convenient locations in Kansas. Our mission is to 
                help you live your best life by providing expert care and valuable educational resources to empower you
                with knowledge about your heart health. Your journey to a healthier heart starts with us.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Services}
                title="Our Services"
                description="At Cardiovascular Care, we offer a wide range of services to evaluate and manage your heart and
                  vascular health. Our services include nuclear stress tests, echocardiograms, Holter monitoring, heart
                  rhythm management, and heart failure treatment. For more detailed information, please visit our
                  'Patient Information' tab at the top of the page."
                iconColor="info"
                color="black"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Locations}
                title="Many Locations"
                description="We offer cardiovascular care in multiple convenient locations. Our main offices are in Wichita and
                  Newton, with additional clinics in Valley Center (Dr. Horn's Office), Hesston (Dr. Hall's Office), and
                  Marion (St. Luke's Clinic)."
                iconColor="success"
                color="black"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={Education}
                title="Education"
                description="Expand your knowledge about heart health by visiting our 'Patient Information' tab. Here, you will find 
                  trusted resources and educational materials to help you stay informed and proactive about your
                  cardiovascular health."
                iconColor="danger"
                color="black"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 1 END */}
      </div>
    </div>
  );
}