import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import { DP } from "assets/DataProvider.js";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { CircularProgress } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput";
import { Message } from "@material-ui/icons";
import CustomDialog from "./SectionDialog";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";

const useStyles = makeStyles(notificationsStyles);
const useStylesTable = makeStyles(style);

export default function SectionStaff({staffInfo, updateInMemory, userInfo, revalidate}) {
  const classes = useStyles();
  const tableClasses = useStylesTable();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [notify, setNotify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [addEmail, setAddEmail] = React.useState('');
  const [deleteStaff, setDeleteStaff] = React.useState('');

  const handleStaffDelete = async () => {
    try {
      setLoading(true);
      const updRes = await DP.DELETEWITHAUTHTOKEN(`users/${deleteStaff}`, userInfo.token)
      if(updRes.successMessage) {
        setNotify(false);
        setTimeout(() => setNotify({text: 'The user was successfully deleted!', color: 'success'}), 0);
        updateInMemory()
      }
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify(false);
        setTimeout(() => setNotify({text: e.toString(), color: 'danger'}), 0);
      }
    } finally {
        setLoading(false);
    }
  }

  const handleStaffAdd = async () => {
    try {
        if (!addEmail) {
            return;
        }
        setLoading(true);
        const updRes = await DP.POSTWITHAUTHTOKEN(`users`, {email: addEmail}, userInfo.token)
        if(updRes.successMessage) {
          setNotify(false);
          setTimeout(() => setNotify({text: 'The user was successfully added! Please have them check their email for details.', color: 'success'}), 0);
          updateInMemory()
        }
      } catch (e) {
        console.error(e);
        if(e.toString().includes('401')) {
          console.error(e);
          revalidate();
        } else {
          setNotify(false);
          setTimeout(() => setNotify({text: e.toString(), color: 'danger'}), 0);
        }
      } finally {
          setLoading(false);
      }
  }

  const handleResetStaff = () => {
      setDeleteStaff('');
  }


  const fillButtons = (username) => [
    { color: "danger", icon: Close, tip: 'Delete Staff', onClick: () => setDeleteStaff(username)},
  ].map((prop, key) => {
    return (
      <Tooltip
              id="tooltip-top"
              title={prop.tip}
              placement="top"
              key={key}
            >
      <Button round justIcon size="sm" color={prop.color} key={key} onClick={prop.onClick}>
        <prop.icon />
      </Button>
      </Tooltip>
    );
  });

  const GetStaffEmailFromUsername = (username) => {
      if(!username) {
          return;
      }
      const userObjFind = staffInfo.find((element) => element.Username === username);
      if(userObjFind) {
          const email = GetStaffEmail(userObjFind);
          return email;
      }
      return '';
  }

  const GetStaffName = (userObj) => {
      const firstNameFind = userObj.Attributes.find((element) => element.Name === 'given_name');
      const lastNameFind = userObj.Attributes.find((element) => element.Name === 'family_name');
      return (firstNameFind ? firstNameFind.Value : '') + ' ' + (lastNameFind ? lastNameFind.Value : '');
  }

  const GetStaffEmail = (userObj) => {
      const emailFind = userObj.Attributes.find((element) => element.Name === 'email');
      return emailFind ? emailFind.Value : '';
  }

  const StaffSorted = (searchTerm = false) => {
      if(!staffInfo) {
          return [];
      } 
      let staffInfoInfo = JSON.parse(JSON.stringify(staffInfo));
    // If we have a search term, let's try to add that.
    if(searchTerm) {
      staffInfoInfo = staffInfoInfo.filter((staffMem) =>(GetStaffName(staffMem)).toLowerCase().includes(searchTerm) || GetStaffEmail(staffMem).toLowerCase().includes(searchTerm));
    }
    // Now, let's sort.
    staffInfoInfo = staffInfoInfo.map((staffMem) => [GetStaffName(staffMem), GetStaffEmail(staffMem), fillButtons(staffMem.Username)]);

    return staffInfoInfo;
  }
  return (
    <div>
      <h2 className={classes.title}>Staff Accounts</h2>
      <h5 className={classes.title}> Manage who has access to the Cardiovascular Care Management Portal.</h5>
      {!staffInfo.length && (<CircularProgress />)}
      {staffInfo.length && (
        <div>
          <GridContainer justify="center">
            {/* Are you sure dialog! */}
          <CustomDialog open={deleteStaff ? true : false} onConfirmation={handleStaffDelete} onCancellation={handleResetStaff} dialogContent={<h5>Are you sure you want to delete <strong>{GetStaffEmailFromUsername(deleteStaff)}</strong>?</h5>}/>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}> 
           <CustomInput
                    // white
                    inputRootCustomClasses={classes.inputRootCustomClasses}
                    formControlProps={{
                      className: classes.formControl,
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder: "Search",
                      inputProps: {
                        "aria-label": "Search",
                        className: classes.searchInput,
                      },
                      value: searchTerm,
                      onChange: (e) => {setSearchTerm((e.target.value))},
                    }}
                  />
                  </GridItem>
                  </GridContainer>
      <GridContainer justify="center">
        <Table
            pagination={10}
              tableHead={[
                "Name",
                "Email Address",
                "Actions",
              ]}
              tableData={[
                ...StaffSorted(searchTerm.toLowerCase()),
              ]}
              customCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customClassesForCells={[0, 1, 2]}
              customHeadCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customHeadClassesForCells={[0, 1, 2]}
            />
      </GridContainer>
      <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}> 
            <CustomInput
                        // white
                        inputRootCustomClasses={classes.inputRootCustomClasses}
                        formControlProps={{
                        className: classes.formControl,
                        fullWidth: true
                        }}
                        inputProps={{
                        placeholder: "Email",
                        inputProps: {
                            "aria-label": "Email",
                            className: classes.searchInput,
                        },
                        value: addEmail,
                        onChange: (e) => {setAddEmail(e.target.value)},
                        }}
                    />
            {!loading ? (
                <Button color='primary' round onClick={handleStaffAdd}>
                    Add Staff
                </Button>
                ) : <CircularProgress />}
            </GridItem>
            </GridContainer>
      {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      )}
    </div>
  );
}
