/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import CustomFooter from "components/Footer/CustomFooter.js";
// sections for this page
import CustomHeader from "components/Header/CustomHeader.js"
import AboutUsEducationSection from "views/AboutUsPage/Sections/AboutUsEducationSection.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import SectionPhysicians from "views/AboutUsPage/Sections/SectionPhysicians";

const useStyles = makeStyles(aboutUsStyle);

export default function PhysiciansPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <CustomHeader />
      <Parallax
        image={require("assets/img/physicians.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Our Staff</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionPhysicians />
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
