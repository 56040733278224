import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import { DP } from "assets/DataProvider.js";
import { CircularProgress } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Close from "@material-ui/icons/Close";
import Badge from "components/Badge/Badge.js";
import CustomInput from "components/CustomInput/CustomInput";
import { Tooltip } from "@material-ui/core";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";

const useStyles = makeStyles(notificationsStyles);
const useStylesTable = makeStyles(style);

export default function SectionMessages({messageData, selectedPatient, updateInMemory, userInfo, revalidate}) {
  const classes = useStyles();
  const tableClasses = useStylesTable();
  const [searchTerm, setSearchTerm] = React.useState(selectedPatient || '');
  const [notify, setNotify] = React.useState(false);
//   Provide functions from DP to push updates
  const handleMessageReview = async (patientEmail, messageText, reviewed) => {
    setNotify(false);
    const finalObj = {
      messageText: messageText,
      reviewed: reviewed,
    }
    try {
      const updRes = await DP.PUTWITHAUTHTOKEN(`messages/${patientEmail}`, finalObj, userInfo.token)
      if(updRes.successMessage) {
        setNotify({text: 'The message was successfully updated!', color: 'success'})
        updateInMemory(patientEmail, messageText, reviewed)
      }
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify({text: e.toString(), color: 'danger'})
      }
    }
  }

  const roundButtons = (patientEmail, messageText) => [
    { color: "success", icon: Check, tip: 'Mark Reviewed' },
    { color: "danger", icon: Close, tip: 'Mark Not Reviewed' },
  ].map((prop, key) => {
    return (
      <Tooltip
              id="tooltip-right"
              title={prop.tip}
              placement="right"
              key={key}
            >
      <Button round justIcon size="sm" color={prop.color} key={key} onClick={() => handleMessageReview(patientEmail, messageText, prop.color === 'success')}>
        <prop.icon />
      </Button>
      </Tooltip>
    );
  });
//Special sort and search function for message search
  const MessagesSorted = (searchTerm = false) => {
      let messageObjList = [];
      if(!messageData) {
          return [];
      } else {
          for(let i = 0; i < messageData.length; i++) {
              if(messageData[i].messages && messageData[i].messages.length > 0) {
                  const patientEmail = messageData[i].patientEmail;
                  const patientName = messageData[i].firstName + ' ' + messageData[i].lastName 
                  for(let j = 0; j < messageData[i].messages.length; j++) {
                      messageObjList.push({...messageData[i].messages[j], patientEmail: patientEmail, patientName: patientName})
                  }
              }
          }
      }
    // If we have a search term, let's try to add that.
    if(searchTerm) {
        messageObjList = messageObjList.filter((message) => message.patientName.toLowerCase().includes(searchTerm) || message.patientEmail.toLowerCase().includes(searchTerm) || message.messageText.toLowerCase().includes(searchTerm));
    }
    // Now, let's sort.
    messageObjList.sort((a,b) => {
        // Let's always sort on reviewed first
        if(a.reviewed && !b.reviewed) {
            return 1;
        } else if (!a.reviewed && b.reviewed) {
            return -1
        } else {
            // Now we sort by time
            if((new Date(a.messageDate)).getTime() > (new Date(b.messageDate)).getTime()) {
                return -1;
            } else if((new Date(a.messageDate)).getTime() < (new Date(b.messageDate)).getTime()) {
                return 1;
            } else {
                return 0;
            }
        }
    })
    // messageObjList = messageObjList.map((message) => [message.patientName, message.messageText, new Date(message.messageDate).toLocaleString(), message.reviewed ? <span style={{color: '#4caf50'}}>Reveiwed</span> : <span style={{color: '#f44336'}}>Needs Reveiwed</span>, message.patientEmail, roundButtons])
    messageObjList = messageObjList.map((message) => [message.patientName, <strong>{message.messageText}</strong>, new Date(message.messageDate).toLocaleString(), message.reviewed ? <Badge color="success">Reveiwed</Badge> : <Badge color="danger">Not Reveiewed</Badge>, message.patientEmail, roundButtons(message.patientEmail, message.messageText)])
    return messageObjList;
  }
  return (
    <div>
      <h2 className={classes.title}>Patient Messages</h2>
      {!messageData.length && (<CircularProgress />)}
      {messageData.length && (
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}> 
           <CustomInput
                    // white
                    inputRootCustomClasses={classes.inputRootCustomClasses}
                    formControlProps={{
                      className: classes.formControl,
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder: "Search",
                      inputProps: {
                        "aria-label": "Search",
                        className: classes.searchInput,
                      },
                      value: searchTerm,
                      onChange: (e) => {setSearchTerm((e.target.value))},
                    }}
                  />
                  </GridItem>
                  </GridContainer>
      <GridContainer justify="center">
        <Table
            pagination={10}
              tableHead={[
                "Name",
                "Message Text",
                "Date",
                "Reviewed",
                "Email",
                "Actions",
              ]}
              tableData={[
                ...MessagesSorted(searchTerm.toLowerCase()),
              ]}
              customCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5]}
              customHeadCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
            />
      </GridContainer>
      {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      )}
    </div>
  );
}
