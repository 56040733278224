/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import CustomFooter from "components/Footer/CustomFooter.js";
// sections for this page
import CustomHeader from "components/Header/CustomHeader.js"
import AboutUsEducationSection from "views/AboutUsPage/Sections/AboutUsEducationSection.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import SectionPhysicians from "./Sections/SectionPhysicians";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <CustomHeader />
      <Parallax
        image={require("assets/img/aboutus.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>About Us</h1>
              <h4>
                At Cardiovascular Care, our mission is to enhance your quality of life by addressing and resolving your
                heart health issues. We strive to help you enjoy life to the fullest while we focus on preventing and
                reducing future health problems. Our dedicated team provides personal care to the highest standard in
                a family-oriented setting. From our heart to yours, we are committed to your well-being. - Dr. Amirani
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionPhysicians />
		      <AboutUsEducationSection />
          <SectionOffice showContactUs />
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
