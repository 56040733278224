import {
  container,
  title,
  whiteColor,
  section,
} from "assets/jss/material-kit-pro-react.js";

const notificationsStyles = {
  section: {
    display: "flex",
    // border: "0",
    // borderRadius: "3px",
    padding: "5rem 0",
    // marginBottom: "20px",
    width: "100%!important",
    transition: "all 150ms ease 0s",
    // alignItems: "center",
    // flexFlow: "row nowrap",
    justifyContent: "center",
    position: "fixed",
    zIndex: '2',
  },
  section2: {
    display: "flex",
    // border: "0",
    // borderRadius: "3px",
    // padding: "5rem 0",
    // marginBottom: "20px",
    width: "100%!important",
    transition: "all 150ms ease 0s",
    // alignItems: "center",
    // flexFlow: "row nowrap",
    justifyContent: "center",
    // position: "fixed",
    zIndex: '2',
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  space70: {
    height: "70px",
    display: "block",
  },
  container,
};

export default notificationsStyles;
