import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import { DP } from "assets/DataProvider.js";
import { CircularProgress } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Close from "@material-ui/icons/Close";
import Badge from "components/Badge/Badge.js";
import CustomInput from "components/CustomInput/CustomInput";
import { Tooltip } from "@material-ui/core";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import FormatQuote from "@material-ui/icons/FormatQuote";
import { NewFormPDFGenerator } from "./NewFormPDFGenerator";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(notificationsStyles);
const useStylesTable = makeStyles(style);

export default function SectionNewForms({newFormsData, updateInMemory, userInfo, revalidate}) {
  const classes = useStyles();
  const tableClasses = useStylesTable();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [notify, setNotify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
//   Provide functions from DP to push updates
  const handleNewFormReview = async (patientEmail, newFormDate, reviewed) => {
    setNotify(false);
    setLoading(true);
    const finalObj = {
      newFormDate: newFormDate,
      reviewed: reviewed,
    }
    try {
      const updRes = await DP.PUTWITHAUTHTOKEN(`newforms/${patientEmail}`, finalObj, userInfo.token)
      if(updRes.successMessage) {
        setNotify({text: 'The form was successfully updated!', color: 'success'})
        updateInMemory(patientEmail, newFormDate, reviewed)
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setLoading(false);
        setNotify({text: e.toString(), color: 'danger'})
      }
    }
  }

  const handleViewNewForm = (patientEmail, newFormDate) => {
    setLoading(true);
    // First we need to find our correct object
    let patientIndex, formIndex = -1;
    for(let i = 0; i < newFormsData.length; i++) {
      if(newFormsData[i].patientEmail === patientEmail) {
        // We found the patient, now find the correct form
        patientIndex = i;
        for(let j = 0; j < newFormsData[i].newForm.length; j++) {
          if(newFormsData[i].newForm[j].newFormDate === newFormDate) {
            formIndex = j;
          }
        }
      }
    }
    // Make sure we found something before opening the file
    if(patientIndex !== -1 && formIndex !== -1) {
      // Let's construct the object we need to pass
      const newFormCopy = JSON.parse(JSON.stringify(newFormsData[patientIndex].newForm[formIndex]));
      newFormCopy.patientEmail = newFormsData[patientIndex].patientEmail;
      newFormCopy.emailVerified = newFormsData[patientIndex].emailVerified;
      pdfMake.createPdf(NewFormPDFGenerator(newFormCopy)).open();
    } else {
      console.error('Failed to find patient and form info given email:', patientEmail, 'and date:', newFormDate)
    }
    setLoading(false);
  }

  const roundButtons = (patientEmail, newFormDate) => [
    { color: "info", icon: FormatQuote, tip: 'View New Patient Form', onClick: () => handleViewNewForm(patientEmail, newFormDate)},
    { color: "success", icon: Check, tip: 'Mark Reviewed', onClick: () => handleNewFormReview(patientEmail, newFormDate, true)},
    { color: "danger", icon: Close, tip: 'Mark Not Reviewed', onClick: () => handleNewFormReview(patientEmail, newFormDate, false) },

  ].map((prop, key) => {
    return (
      <Tooltip
              id="tooltip-top"
              title={prop.tip}
              placement="top"
              key={key}
            >
      <Button round justIcon size="sm" color={prop.color} key={key} onClick={prop.onClick}>
        <prop.icon />
      </Button>
      </Tooltip>
    );
  });
//Special sort and search function for message search
  const NewFormsSorted = (searchTerm = false) => {
      let newFormsObjList = [];
      if(!newFormsData) {
          return [];
      } else {
          for(let i = 0; i < newFormsData.length; i++) {
              if(newFormsData[i].newForm && newFormsData[i].newForm.length > 0) {
                  const patientEmail = newFormsData[i].patientEmail;
                  const patientName = newFormsData[i].firstName + ' ' + newFormsData[i].lastName 
                  for(let j = 0; j < newFormsData[i].newForm.length; j++) {
                      newFormsObjList.push({...newFormsData[i].newForm[j], patientEmail: patientEmail, patientName: patientName})
                  }
              }
          }
      }
    // If we have a search term, let's try to add that.
    if(searchTerm) {
        newFormsObjList = newFormsObjList.filter((newForm) => newForm.patientName.toLowerCase().includes(searchTerm) || newForm.patientEmail.toLowerCase().includes(searchTerm));
    }
    // Now, let's sort.
    newFormsObjList.sort((a,b) => {
        // Let's always sort on reviewed first
        if(a.reviewed && !b.reviewed) {
            return 1;
        } else if (!a.reviewed && b.reviewed) {
            return -1
        } else {
            // Now we sort by time
            if((new Date(a.newFormDate)).getTime() > (new Date(b.newFormDate)).getTime()) {
                return -1;
            } else if((new Date(a.newFormDate)).getTime() < (new Date(b.newFormDate)).getTime()) {
                return 1;
            } else {
                return 0;
            }
        }
    })
    newFormsObjList = newFormsObjList.map((newForm) => [newForm.patientName, new Date(newForm.newFormDate).toLocaleString(), newForm.reviewed ? <Badge color="success">Reveiwed</Badge> : <Badge color="danger">Not Reveiewed</Badge>, newForm.patientEmail, roundButtons(newForm.patientEmail, newForm.newFormDate)])
    return newFormsObjList;
  }
  return (
    <div>
      <h2 className={classes.title}>New Patient Forms</h2>
      {!newFormsData.length && (<CircularProgress />)}
      {newFormsData.length && (
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}> 
           <CustomInput
                    // white
                    inputRootCustomClasses={classes.inputRootCustomClasses}
                    formControlProps={{
                      className: classes.formControl,
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder: "Search",
                      inputProps: {
                        "aria-label": "Search",
                        className: classes.searchInput,
                      },
                      value: searchTerm,
                      onChange: (e) => {setSearchTerm((e.target.value))},
                    }}
                  />
                  </GridItem>
                  </GridContainer>
      <GridContainer justify="center">
        <Table
            pagination={10}
              tableHead={[
                "Name",
                "Date",
                "Reviewed",
                "Email",
                "Actions",
              ]}
              tableData={[
                ...NewFormsSorted(searchTerm.toLowerCase()),
              ]}
              customCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5]}
              customHeadCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
            />
      </GridContainer>
      {loading && <CircularProgress />}
      {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      )}
    </div>
  );
}
