import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// icon
import Mail from "@material-ui/icons/Mail"
// 
import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
// office
import office1 from "assets/img/office/wichita1.jpeg";
import office2 from "assets/img/office/wichita2.jpeg";
import office3 from "assets/img/office/wichita3.jpeg";
import office4 from "assets/img/office/wichita4.jpeg";
import office5 from "assets/img/office/wichita5.jpeg";
import newton1 from "assets/img/office/newton1.jpeg";
import newton2 from "assets/img/office/newton2.jpeg";
import newton3 from "assets/img/office/newton3.jpeg";
import newton4 from "assets/img/office/newton4.jpeg";
import newton5 from "assets/img/office/newton5.jpeg";

const useStyles = makeStyles(officeStyle);

export default function SectionOffice({showContactUs}) {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.title}>Our Offices</h2>
          <h4 className={classes.description}>
            Our main offices are located in Wichita and Newton. We also have availability to see patients in the following Kansas locations: Hesston, Marion, and Winfield.<br/>
            {showContactUs && (`Please find more info on our 'Contact Us' page.`)}
          </h4>
          {showContactUs && (<Link to={"/contact-us"}>
            <Button
              color="danger"
              className={classes.navButton}
            >
              <Mail /> Contact Us
            </Button>
          </Link>)}
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
      <h3 className={classes.title}>Wichita</h3> 
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office4}
            alt="office1"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office2}
            alt="office2"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office5}
            alt="office3"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office1}
            alt="office4"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={office3}
            alt="office5"
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
      <h3 className={classes.title}>Newton</h3> 
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={newton1}
            alt="Newton Cardiovascular Care Office"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={newton2}
            alt="Newton Cardiovascular Care Office"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={newton3}
            alt="Newton Cardiovascular Care Office"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={newton5}
            alt="Newton Cardiovascular Care Office"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src={newton4}
            alt="Newton Cardiovascular Care Office"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
