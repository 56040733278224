import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import SectionBanner from "../../PresentationPage/Sections/SectionBanner.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { DP } from "assets/DataProvider.js";
import { CircularProgress } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Badge from "components/Badge/Badge.js";

const useStylesBasic = makeStyles(basicsStyle);
const useStyles = makeStyles(notificationsStyles);

export default function SectionEmail({ emailData, userInfo, revalidate }) {
  const classes = useStyles();
  const basicClasses = useStylesBasic();
  const [additionalEmail, setAdditionalEmail] = React.useState('');
  const [additionalEmailList, setAdditionalEmailList] = React.useState([]);
  const [emailList, setEmailList] = React.useState([]);
  const [emailMessage, setEmailMessage] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const [notify, setNotify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleEmailSend = async () => {
    // validate quickly
    const finalPostObj = {
      emailList: [...new Set(emailList.concat(additionalEmailList))],
      emailMessage: emailMessage
    }
    if(!finalPostObj.emailList.length) {
      alert('You must select at least one patient or email to send to!');
      return;
    }
    if(!finalPostObj.emailMessage) {
      alert('You must enter a message to email!');
      return;
    }
    // now update
    try {
      setLoading(true);
      const updRes = await DP.POSTWITHAUTHTOKEN('email', finalPostObj, userInfo.token)
      if(updRes.successMessage) {
        setNotify({text: 'Your email was successfully sent!', color: 'success'})
      }
      setLoading(false);
      setSubmitted(true);
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify({text: e.toString(), color: 'danger'})
        setLoading(false);
      }
    }
  }


  const EmailBadgeRenderer = () => {
    const concatandset = [...new Set(emailList.concat(additionalEmailList))]
    return concatandset.map((email) => {
      return (
        <Badge key={email} color="success">{email}</Badge>
      );
    })
  };


  const handleEmailAdd = () => {
    if (!additionalEmail.includes('@')) {
      alert('Invalid Email Entered. Please make sure the email you have entered is valid.');
      return;
    }
    const copyEmailList = [...additionalEmailList, additionalEmail.toLowerCase()];
    setAdditionalEmailList([...new Set(copyEmailList)]);
    setAdditionalEmail('');
  }

  return (
    <div>
      <h2 className={classes.title}>Email Patients</h2>
      {!emailData.length && (<CircularProgress />)}
      {emailData.length && (
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h5 className={classes.title}>To: {emailList.length ? '' : 'No Emails Selected'}</h5>
              <EmailBadgeRenderer />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth className={basicClasses.selectFormControl}>
                <InputLabel
                  htmlFor="multiple-select"
                  className={basicClasses.selectLabel}
                >
                  Select Patients
                </InputLabel>
                <Select
                  multiple
                  value={emailList}
                  onChange={(e) => setEmailList(e.target.value)}
                  MenuProps={{
                    className: basicClasses.selectMenu,
                    classes: { paper: basicClasses.selectPaper },
                  }}
                  classes={{ select: basicClasses.select }}
                  inputProps={{
                    name: "multipleSelect",
                    id: "multiple-select",
                  }}
                >
                  {emailData.map((patient) => {
                    return (
                      <MenuItem
                        key={patient.patientEmail}
                        disabled={patient.doNotEmail}
                        value={patient.patientEmail}
                        classes={{
                          root: basicClasses.selectMenuItem,
                        }}
                      >
                        {patient.firstName + ' ' + patient.lastName + ' - ' + patient.patientEmail}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10}>
              <CustomInput
                labelText="Additional Email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: additionalEmail,
                  onChange: (e) => { setAdditionalEmail(e.target.value) },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button color="primary" onClick={handleEmailAdd}>
                + Add
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Please enter your message to be sent here..."
                id="textarea-input"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  value: emailMessage,
                  onChange: (e) => { setEmailMessage(e.target.value) },
                }}
              />
            </GridItem>
            </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              {loading ? <CircularProgress /> : (<Button round color="primary" disabled={submitted} onClick={handleEmailSend}>
                Send Emails
              </Button>)}
            </GridItem>
          </GridContainer>
          {notify && (<SnackbarContent
            message={notify.text}
            color={notify.color}
            icon={notify.color === 'success' ? Check : 'info_outline'}
          />)}
        </div>
      )}
    </div>
  );
}
