/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { Healing } from "@material-ui/icons";
import { LocalHospital } from "@material-ui/icons";
import { SignalCellularAlt } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import ServicesList from "assets/img/serviceslist.jpg";
import ProceduresList from "assets/img/procedureslist.jpg";
import Telehealth from "assets/img/telehealth.jpg";

const useStyles = makeStyles(projectsStyle);

export default function SectionProcsAndServices({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Project 4 START */}
      <div className={classes.projects + " " + classes.projects4}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Our Different Services and Procedures
              </h2>
              <h5 className={classes.description}>
                We offer a comprehensive range of services to diagnose and treat cardiac issues, many of which are
                conveniently performed in our office for your comfort. For more information about our services and
                procedures, please refer to the educational sections below or contact us directly.
              </h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card
                background
                className={classes.card4}
                style={{ backgroundImage: `url(${ServicesList})` }}
              >
                <CardBody background className={classes.cardBody4}>
                  <Badge color="rose">Services</Badge>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <InfoArea
                className={classes.info4}
                color="black"
                title="In Office Testing Services Include"
                description={<div>Treadmill Stress Test<br/>
                  Nuclear Treadmill Stress Test<br/>
                  Echocardiograms<br/>
                  Vascular Ultrasound<br/>
                  Vein Mapping<br/>
                  Vein Ablation<br/>
                  Pacemaker Checks</div>}
                icon={Healing}
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <InfoArea
                className={classes.info4}
                color="black"
                title="Procedures Include"
                description={<div>
                  Pacemaker Implantation/Removal<br/>
                  Heart Catheterizations<br/>
                  Coronary Stent Placement<br/>
                  Arteriogram<br/>
                  Transesophageal Echo<br/>
                  Cardioversion<br/>
                  Ablations<br/>
                </div>}
                icon={LocalHospital}
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <Card
                background
                className={classes.card4}
                style={{ backgroundImage: `url(${ProceduresList})` }}
              >
                <CardBody background className={classes.cardBody4}>
                  <Badge color="rose">Procedures</Badge>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card
                background
                className={classes.card4}
                style={{ backgroundImage: `url(${Telehealth})` }}
              >
                <CardBody background className={classes.cardBody4}>
                  <Badge color="rose">Telehealth</Badge>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <InfoArea
                className={classes.info4}
                color="black"
                title="Telehealth / Telemedicine Services"
                description="We offer telehealth/telemedicine services. This is the facilitation of our health services through digital communication. 
                This allows long distance patient and clinical contact, care, advice, reminders, education, and monitoring. Please contact us if you'd like to find out more
                about our telehealth services."
                icon={SignalCellularAlt}
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 4 END */}
    </div>
  );
}
