export const NewFormPDFGenerator = (newFormData) => { return {
    content: [
      { text: 'New Patient Form', style: 'header' },
      { text: 'Cardiovascular Care P.A.', style: 'subheader' },
      { text: '925 N Hillside St. Wichita, KS 67206\n\n\n\n', style: 'subsubheader' },
      { text: 'General Information\n\n', style: 'sectionHeader' },
      { text: [
          'Full Name: ',
          {text: `${newFormData.firstName} ${newFormData.middileInitial ? newFormData.middileInitial + ' ' + newFormData.lastName : newFormData.lastName}`, style: 'field'},
          '    DOB: ',
          {text: newFormData.dateOfBirth, style: 'field'},
          '    Phone Number: ',
          {text: newFormData.phoneNumber, style: 'field'},
      ]},
      { text: [
           '    Address: ',
          {text: newFormData.address, style: 'field'},
           '    City: ',
          {text: newFormData.city, style: 'field'},
           '    State: ',
          {text: newFormData.state, style: 'field'},
           '    Zip: ',
          {text: newFormData.zipCode, style: 'field'},
          ]},
          { text: [
            '    Insurance Provider: ',
            {text: newFormData.insuranceProvider, style: 'field'},
             '    Policy Holider: ',
            {text: newFormData.insurancePolicyHolder, style: 'field'},
            '    Policy Number: ',
            {text: newFormData.insurancePolicyNumber, style: 'field'},
          ]},
          { text: [
           '    Email: ',
          {text: newFormData.patientEmail, style: 'field', color: newFormData.emailVerified ? 'green' : 'red'},
          ]},
          '\n\n\n\n',
           { text: 'Emergency Contact Disclosure List\n\n', style: 'sectionHeader' },
           { text: [
           '    I give permission to leave messages concering my healthcare/appointments: ',
          {text: newFormData.leaveMessagesConsent, style: 'field'},
          ]},
          {text: newFormData.leaveMessagesConsent === 'Yes' ? 'Contacts:' : '', style: 'field'},
          ...contactInfoHandler(newFormData.contactInfo ? newFormData.contactInfo : []),
          '\n\n\n\n',
          { text: 'Permission to Disclose To Those Involved In My Care\n\n', style: 'sectionHeader' },
          { text: [
           '    I hereby allow Cardiovascular Care, P.A. to disclose the following Protected Health Information: ',
          {text: getFriendlyNameDiclosureOptions(newFormData.permissionDisclosureOptions ? newFormData.permissionDisclosureOptions.permissionsAllowedToDisclose : ''), style: 'field'},
          ]},
          'to the following people via phone because they are involved with my healthcare or payment:',
          {text: newFormData.permissionDisclosureOptions ? 'Contacts:' : '', style: 'field'},
          ...handleDisclosureContacts(newFormData.permissionDisclosureOptions ? newFormData.permissionDisclosureOptions : ''),
          '\n\n\n\n\n\n\n\n\n\n\n\n\n',
          'Patient Signature: _____________________________________________________________',
          '\n',
          'Date: __________________________________________________________________________'
    ],
    
    footer: {
              text: `This form was submitted at ${new Date(newFormData.newFormDate).toLocaleString()}.`,
              style: 'footer'
          },

    header: {
        text: newFormData.reviewed ? '' : 'New Form Has Not Been Reviewed',
        fontSize: 8,
        alignment: 'right',
        color: 'red'
    },
    
    defaultStyle: {
        alignment: 'center'
    },
    styles: {
      header: {
        fontSize: 22,
        bold: true,
        alignment: 'center'
      },
      subheader: {
        fontSize: 17,
        bold: true,
        alignment: 'center'
      },
      subsubheader: {
        fontSize: 10,
        bold: false,
        alignment: 'center'
      },
      sectionHeader: {
        bold: true,
        alignment: 'center',
        decoration: 'underline',
      },
      field: {
        decoration: 'underline',
        bold: true,
        alignment: 'center',
      },
      footer: {
          fontSize: 8
      }
    }
  };
};

const contactInfoHandler = (contactInfoArray) => {
    const finalReturn = [];
    for(let i = 0; i < contactInfoArray.length; i++ ) {
        finalReturn.push({ text: [
            '    Name: ',
           {text: contactInfoArray[i].contactName, style: 'field'},
           '    Relationship: ',
           {text: contactInfoArray[i].relationship, style: 'field'},
           '    Phone Number: ',
           {text: contactInfoArray[i].phoneNumber, style: 'field'},
           ]},)
    }
    return finalReturn;
}

const getFriendlyNameDiclosureOptions = (permissionsAllowedToDiscloseString) => {
    if(!permissionsAllowedToDiscloseString) {
        return 'Not Allowed to Disclose'
    } else {
        const translationMap = {
            APTDATETIMES: 'Appointment Date and Times',
            TESTRESULTS: 'Test Results',
            OTHERHEALTHINFO: 'Other Health Info',
        }
        const keys = Object.keys(translationMap)
        for(let i = 0; i < keys.length; i++) {
            permissionsAllowedToDiscloseString = permissionsAllowedToDiscloseString.replace(keys[i], translationMap[keys[i]]);
        }
        return permissionsAllowedToDiscloseString;
    }
}

const handleDisclosureContacts = (permissionDisclosureOptions) => {
    if(!permissionDisclosureOptions) {
        return [];
    }
    const finalReturn = [];
    const keys = Object.keys(permissionDisclosureOptions);
    if(keys.includes('spouseName')) {
        finalReturn.push( { text: [
            '    Name: ',
           {text: permissionDisclosureOptions.spouseName, style: 'field'},
            '    Relationship: ',
           {text: 'Spouse', style: 'field'},
           '    Phone Number: ',
           {text: permissionDisclosureOptions.phoneNumber, style: 'field'},
           ]},)
    } else if (keys.includes('familyFriendName')) {
        finalReturn.push( { text: [
            '    Name: ',
           {text: permissionDisclosureOptions.familyFriendName, style: 'field'},
            '    Relationship: ',
           {text: 'Family Friend', style: 'field'},
           '    Phone Number: ',
           {text: permissionDisclosureOptions.phoneNumber, style: 'field'},
           ]},)
    }
    else if (keys.includes('childName')) {
        finalReturn.push( { text: [
            '    Name: ',
           {text: permissionDisclosureOptions.childName, style: 'field'},
            '    Relationship: ',
           {text: 'Child', style: 'field'},
           '    Phone Number: ',
           {text: permissionDisclosureOptions.phoneNumber, style: 'field'},
           ]},)
    }
    else if (keys.includes('otherName')) {
        finalReturn.push( { text: [
            '    Name: ',
           {text: permissionDisclosureOptions.otherName, style: 'field'},
            '    Relationship: ',
           {text: 'Other', style: 'field'},
           '    Phone Number: ',
           {text: permissionDisclosureOptions.phoneNumber, style: 'field'},
           ]},)
    }
    return finalReturn;
}