import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
// tab sections
import SectionBannerConfig from "./SectionBannerConfig";
import SectionMessages from "./SectionMessages";
import SectionPatients from './SectionPatients';
import SectionNotifications from "./SectionNotifications";
import SectionStaff from "./SectionStaff";
import SectionEmail from "./SectionEmail";

import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";
import { DP } from "assets/DataProvider";
import SectionNewForms from "./SectionNewForms";
import NewFormPage from "views/NewFormPage/NewFormPage";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionManagementPills(props) {
  const activeToPillMap = {
    0: 'Banner',
    1: 'Billing',
    2: 'Email',
    3: 'Messages',
    4: 'NewPatientForms',
    5: 'Notifications',
    6: 'Patients',
    7: 'Staff',
  }
  const needsPatientInfo = ['Billing', 'Email', 'Messages', 'NewPatientForms', 'Patients'];
  const classes = useStyles();
  const [patientInfo, setPatientInfo] = React.useState(undefined);
  const [notificationInfo, setNotificationInfo] = React.useState(undefined);
  const [staffInfo, setStaffInfo] = React.useState(undefined);
  const [selectedPatient, setSelectedPatient] = React.useState('');
  const [tempActive, setTempActive] = React.useState(0);
  // Update the initial loading state when making changes to loading
  const [loadingStatus, setLoadingStatus] = React.useState({
    patientInfo: false,
    notificationInfo: false,
    staffInfo: false,
  })

  // handle updating message in patient info section
  const handleMessageUpdateInMemory = (patientEmail, messageText, reviewed) => {
    const patientInfoCopy = JSON.parse(JSON.stringify(patientInfo));
    // find the record to update
    for(let i = 0; i < patientInfoCopy.length; i++) {
      if(patientInfoCopy[i].patientEmail === patientEmail) {
        // Found the patient, need to find the message and update
        for(let j = 0; j < patientInfoCopy[i].messages.length; j++) {
          if(patientInfoCopy[i].messages[j].messageText === messageText) {
            patientInfoCopy[i].messages[j].reviewed = reviewed;
          }
        }
      }
    }
    setPatientInfo(patientInfoCopy);
  }

  const handleNewFormUpdateInMemory = (patientEmail, newFormDate, reviewed) => {
    const patientInfoCopy = JSON.parse(JSON.stringify(patientInfo));
    // find the record to update
    for(let i = 0; i < patientInfoCopy.length; i++) {
      if(patientInfoCopy[i].patientEmail === patientEmail) {
        // Found the patient, need to find the message and update
        for(let j = 0; j < patientInfoCopy[i].newForm.length; j++) {
          if(patientInfoCopy[i].newForm[j].newFormDate === newFormDate) {
            patientInfoCopy[i].newForm[j].reviewed = reviewed;
          }
        }
      }
    }
    setPatientInfo(patientInfoCopy);
  }

  // Method is DELETE or UPDATE
  // Delete, remove that element from array
  // Update, update the email verified status correctly
  const handlePatientInfoUpdateInMemory = (method, patientEmail, emailVerified = false) => {
    const patientInfoCopy = JSON.parse(JSON.stringify(patientInfo));
    for(let i = 0; i < patientInfoCopy.length; i++) {
      if(patientInfoCopy[i].patientEmail === patientEmail) {
        // Do we want to delete this, or update it?
        if(method === 'DELETE') {
          patientInfoCopy.splice(i,1);
        } else if(method = 'UPDATE') {
          patientInfoCopy[i].emailVerified = emailVerified;
        }
        else {
          console.error('Method:', method, 'is not a valid method');
        }
      } 
    }
    setPatientInfo(patientInfoCopy);
  }

  // If we call a screen that needs to load in, then let's get the patient info.
  const GetPatientInfo = async () => {
    try {
      setLoadingStatus(status => {return {...status, patientInfo: true}})
      const getRes = await DP.GETWITHAUTHTOKEN('patients', props.userInfo.token);
      setPatientInfo(getRes);
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        props.revalidate();
        return;
      }
    } finally {
      setLoadingStatus(status => {return {...status, patientInfo: false}})

    }
  }

  const GetNotificationInfo = async () => {
    try {
      setLoadingStatus(status => {return {...status, notificationInfo: true}})
      const getRes = await DP.GETWITHAUTHTOKEN('notifications', props.userInfo.token);
      setNotificationInfo(getRes);
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        props.revalidate();
        return;
      }
    } finally {
      setLoadingStatus(status => {return {...status, notificationInfo: false}})
    }
  }

  const GetStaffInfo = async () => {
    try {
      setLoadingStatus(status => {return {...status, staffInfo: true}})
      const getRes = await DP.GETWITHAUTHTOKEN('users', props.userInfo.token);
      setStaffInfo(getRes.Users);
    } catch(e) {
      console.error(e);
      if(e.toString().includes('401')) {
        props.revalidate();
        return;
      }
    } finally {
      setLoadingStatus(status => {return {...status, staffInfo: false}})
    }
  }

  const HandlePatientInfoTabChange = async (active) => {
    if(active !== 3) {
      setSelectedPatient('')
      setTempActive(active);
    }
    if(!patientInfo && needsPatientInfo.includes(activeToPillMap[active]) && !loadingStatus.patientInfo) {
      await GetPatientInfo();
    }
    if(!notificationInfo && activeToPillMap[active] === 'Notifications' && !loadingStatus.notificationInfo) {
      await GetNotificationInfo();
    }
    if(!staffInfo && activeToPillMap[active] === 'Staff' && !loadingStatus.staffInfo) {
      await GetStaffInfo();
    }
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={9} className={classes.textCenter}>
          <NavPills
            alignCenter
            color="danger"
            parentStateSet={HandlePatientInfoTabChange}
            active={selectedPatient !== '' ? 3 : tempActive}
            tabs={[
              {
                tabButton: "Banner",
                tabContent: <SectionBannerConfig userInfo={props.userInfo} revalidate={props.revalidate}/>,
              },
              {
                tabButton: "Billing",
                tabContent: <h1>Coming Soon</h1>,
              },
              {
                tabButton: "Email",
                tabContent: <SectionEmail emailData={patientInfo ? patientInfo : []}  userInfo={props.userInfo} revalidate={props.revalidate} />,
              },
              {
                tabButton: "Messages",
                tabContent: <SectionMessages messageData={patientInfo ? patientInfo.map((patient) => patient.messages && patient.messages.length > 0 ? patient : undefined).filter((patient) => patient !== undefined) : []} selectedPatient={selectedPatient} updateInMemory={handleMessageUpdateInMemory} userInfo={props.userInfo} revalidate={props.revalidate} />,
              },
              {
                tabButton: "New Patient Forms",
                tabContent: <SectionNewForms newFormsData={patientInfo ? patientInfo.map((patient) => patient.newForm && patient.newForm.length > 0 ? patient : undefined).filter((patient) => patient !== undefined) : []} updateInMemory={handleNewFormUpdateInMemory} userInfo={props.userInfo} revalidate={props.revalidate} />
              },
              {
                tabButton: "Notifications",
                tabContent: <SectionNotifications notificationData={notificationInfo} updateInMemory={GetNotificationInfo} userInfo={props.userInfo} revalidate={props.revalidate} />,
              },
              {
                tabButton: "Patients",
                tabContent: <SectionPatients patientData={patientInfo ? patientInfo : []} setSelectedPatient={setSelectedPatient} updateInMemory={handlePatientInfoUpdateInMemory} userInfo={props.userInfo} revalidate={props.revalidate}/>,
              },
              {
                tabButton: "Staff",
                tabContent: <SectionStaff staffInfo={staffInfo ? staffInfo : []} updateInMemory={GetStaffInfo} userInfo={props.userInfo} revalidate={props.revalidate}/>,
              },
            ]}
          />
          <div className={classes.tabSpace} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
