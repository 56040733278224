/*eslint-disable*/
import React from "react";

export default function AuthCodePage() {
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.hash);
    const params = Object.fromEntries(queryParams.entries());
    // Validate that we got our required params
    if(!params['#id_token'] || !params.expires_in || !params.token_type) {
      console.error('Invalid Params Provided:', params);
      return;
    }
    // Now let's set these items in local storage
    // Create date
    const expiresTime = new Date();
    expiresTime.setTime(expiresTime.getTime() + params.expires_in * 1000)
    const tokendetails = {
      id_token: params['#id_token'],
      expires_in: params.expires_in,
      type: params.token_type,
      expire_time: expiresTime.getTime(),
    }
    localStorage.setItem('authToken', JSON.stringify(tokendetails));
    window.location.replace('/managementportal');
  }, [])
  return (null);
}
