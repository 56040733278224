import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import pillsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(pillsStyle);

export default function SectionMapPills(props) {
  const classes = useStyles();
  return (
    <div className={classes.section2}>
      <div className={classes.container}>
        <div id="navigation-pills" className={classes.centerButtons}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={6} lg={6}>
              <NavPills
              parentStateSet={props.parentStateSet ? props.parentStateSet : undefined}
                color="danger"
                tabs={[
                  {
                    tabButton: "Wichita",
                  },
                  {
                    tabButton: "Newton",
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
