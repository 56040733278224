const SiteConfig = require('../assets/SiteConfig.js');

export const DP = {
	GET: async (url) => {
		const fetchRes = await fetch(SiteConfig.SiteConfig.APIURL + url, {
			method: 'GET',
			headers: {
				'X-API-Key': SiteConfig.SiteConfig.APIKey,
			}
		});
		return await GetJSONResults(fetchRes);
	},
	POST: async (url, body) => {
		const fetchRes = await fetch(SiteConfig.SiteConfig.APIURL + url, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'X-API-Key': SiteConfig.SiteConfig.APIKey,
			}
		});
		return await GetJSONResults(fetchRes);
	},
	DELETE: async (url) => {
		const fetchRes = await fetch(SiteConfig.SiteConfig.APIURL + url, {
			method: 'DELETE',
			headers: {
				'X-API-Key': SiteConfig.SiteConfig.APIKey,
			},
		});
		return await GetJSONResults(fetchRes);
	},
	GETWITHAUTHTOKEN: async (url, authToken) => {
		const fetchRes = await fetch(SiteConfig.SiteConfig.APIURL + url, {
			method: 'GET',
			headers: {
				'X-API-Key': SiteConfig.SiteConfig.APIKey,
				'Authorization': authToken
			}
		});
		return await GetJSONResults(fetchRes);
	},
	PUTWITHAUTHTOKEN: async (url, body, authToken) => {
		const fetchRes = await fetch(SiteConfig.SiteConfig.APIURL + url, {
			method: 'PUT',
			headers: {
				'X-API-Key': SiteConfig.SiteConfig.APIKey,
				'Authorization': authToken
			},
			body: JSON.stringify(body),
		});
		return await GetJSONResults(fetchRes);
	},
	DELETEWITHAUTHTOKEN: async (url, authToken) => {
		const fetchRes = await fetch(SiteConfig.SiteConfig.APIURL + url, {
			method: 'DELETE',
			headers: {
				'X-API-Key': SiteConfig.SiteConfig.APIKey,
				'Authorization': authToken
			},
		});
		return await GetJSONResults(fetchRes);
	},
	POSTWITHAUTHTOKEN: async (url, body, authToken) => {
		const fetchRes = await fetch(SiteConfig.SiteConfig.APIURL + url, {
			method: 'POST',
			headers: {
				'X-API-Key': SiteConfig.SiteConfig.APIKey,
				'Authorization': authToken
			},
			body: JSON.stringify(body),
		});
		return await GetJSONResults(fetchRes);
	},
};

async function GetJSONResults(fetchRes) {
	if(fetchRes.ok) {
		return await fetchRes.json();
	} else {
		if(fetchRes.status === 401) {
			throw(fetchRes.status + ': Forbidden.');
		} else if(fetchRes.status === 400) {
			const fetchjson = await fetchRes.json();
			throw(fetchRes.status + ' ' + fetchjson.errorMessage)
		}
		throw (fetchRes.status);
	}
}