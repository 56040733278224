import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import { DP } from "assets/DataProvider.js";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { CircularProgress } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput";
import { Message } from "@material-ui/icons";
import CustomDialog from "./SectionDialog";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";

const useStyles = makeStyles(notificationsStyles);
const useStylesTable = makeStyles(style);

export default function SectionPatients({patientData, setSelectedPatient, updateInMemory, userInfo, revalidate}) {
  const classes = useStyles();
  const tableClasses = useStylesTable();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [notify, setNotify] = React.useState(false);
  const [deletePatient, setDeletePatient] = React.useState({
    patientEmail: '',
    patientName: '',
  });

  const handleUserDelete = async () => {
    try {
      const updRes = await DP.DELETEWITHAUTHTOKEN(`patients/${deletePatient.patientEmail}`, userInfo.token)
      if(updRes.successMessage) {
        setNotify(false);
        setTimeout(() => setNotify({text: 'The user was successfully deleted!', color: 'success'}), 0);
        updateInMemory('DELETE', deletePatient.patientEmail)
        setDeletePatient({
          patientEmail: '',
          patientName: '',
        });
      }
    } catch (e) {
      console.error(e);
      setDeletePatient({
        patientEmail: '',
        patientName: '',
      });
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify(false);
        setTimeout(() => setNotify({text: e.toString(), color: 'danger'}), 0);
      }
    }
  }

  const handleEmailVerifiedUpdate = async (patientEmail, emailVerified) => {
    try {
      const updRes = await DP.PUTWITHAUTHTOKEN(`patients/${patientEmail}`, {emailVerified: emailVerified}, userInfo.token)
      if(updRes.successMessage) {
        setNotify(false);
        setTimeout(() => setNotify({text: 'The email verification status was successfully updated!', color: 'success'}), 0);
        updateInMemory('UPDATE', patientEmail, emailVerified)
      }
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify(false);
        setTimeout(() => setNotify({text: e.toString(), color: 'danger'}), 0);
      }
    }
  }

  const handleResetDeletePatient = () => {
    setDeletePatient({
      patientEmail: '',
      patientName: '',
    });
  }

  const fillButtons = (patientEmail, patientName, emailVerified, messageLength) => [
    { color: "info", icon: Message, tip: 'View Messages', disabled: !messageLength, onClick: () => setSelectedPatient(patientEmail)},
    // { color: "info", icon: Person, tip: 'View Forms' },
    { color: "success", icon: Check, tip: 'Mark Email Verified', disabled: emailVerified, onClick: () => handleEmailVerifiedUpdate(patientEmail, true)},
    { color: "danger", icon: Close, tip: 'Delete Patient', onClick: () => setDeletePatient({patientEmail: patientEmail, patientName: patientName})},
  ].map((prop, key) => {
    return (
      <Tooltip
              id="tooltip-top"
              title={prop.tip}
              placement="top"
              key={key}
            >
      <Button disabled={prop.disabled} round justIcon size="sm" color={prop.color} key={key} onClick={prop.onClick}>
        <prop.icon />
      </Button>
      </Tooltip>
    );
  });
//Special sort and search function for message search
  const PatientsSorted = (searchTerm = false) => {
      if(!patientData) {
          return [];
      } 
      let patientDataInfo = JSON.parse(JSON.stringify(patientData));
    // If we have a search term, let's try to add that.
    if(searchTerm) {
      patientDataInfo = patientDataInfo.filter((patient) =>(patient.firstName + ' ' + patient.lastName).toLowerCase().includes(searchTerm) || patient.patientEmail.toLowerCase().includes(searchTerm));
    }
    // Now, let's sort.
    patientDataInfo = patientDataInfo.map((patient) => [patient.firstName + ' ' + (patient.lastName), patient.patientEmail, patient.phoneNumber, patient.messages.length, patient.newForm.length, fillButtons(patient.patientEmail, patient.firstName + ' ' + patient.lastName,patient.emailVerified, patient.messages.length)]).sort((a,b) => a.lastName-b.lastName)
    return patientDataInfo;
  }
  return (
    <div>
      <h2 className={classes.title}>Patient Information</h2>
      {!patientData.length && (<CircularProgress />)}
      {patientData.length && (
        <div>
          <GridContainer justify="center">
            {/* Are you sure dialog! */}
          <CustomDialog open={deletePatient.patientEmail ? true : false} onConfirmation={handleUserDelete} onCancellation={handleResetDeletePatient} dialogContent={<h5>Are you sure you want to delete <strong>{deletePatient.patientName}</strong>?</h5>}/>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}> 
           <CustomInput
                    // white
                    inputRootCustomClasses={classes.inputRootCustomClasses}
                    formControlProps={{
                      className: classes.formControl,
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder: "Search",
                      inputProps: {
                        "aria-label": "Search",
                        className: classes.searchInput,
                      },
                      value: searchTerm,
                      onChange: (e) => {setSearchTerm((e.target.value))},
                    }}
                  />
                  </GridItem>
                  </GridContainer>
      <GridContainer justify="center">
        <Table
            pagination={10}
              tableHead={[
                "Name",
                "Email Address",
                "Phone Numbers",
                '# of Messages',
                '# of Forms',
                "Actions",
              ]}
              tableData={[
                ...PatientsSorted(searchTerm.toLowerCase()),
              ]}
              customCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5]}
              customHeadCellClasses={[
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
                tableClasses.textCenter,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
            />
      </GridContainer>
      {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      )}
    </div>
  );
}
