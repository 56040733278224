import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Danger from "components/Typography/Danger.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import interventional from "assets/img/interventional.jpg";
import electrophysiology from "assets/img/electrophysiology.jpg";

const useStyles = makeStyles(blogsStyle);

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.centerTitle}>Patient Education</h2>
              <br />
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="_blank" onClick={(e) => e.preventDefault()}>
                        <img src={interventional} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${interventional})`,
                          opacity: "1",
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${interventional})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <Danger>
                      <h6 className={classes.cardCategory}>HEALTH</h6>
                    </Danger>
                    <h3 className={classes.cardTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Interventional Cardiology
                      </a>
                    </h3>
                    <p className={classes.descriptionBlack}>
                    Interventional Cardiology is an area of medicine within the subspecialty of cardiology that uses specialized imaging 
                    and other diagnostic techniques to evaluate blood flow and pressure in the coronary arteries and chambers of the heart. 
                    This also includes technical procedures and medications to treat abnormalities that impair the function of the cardiovascular system, all in 
                    order to ensure that people can enjoy their lives for as long as possible.
                    <br/>
                    <strong>Procedures Include</strong>: Pacemaker implantation/removal, heart catheterizations, coronary stent placement, arteriogram, transesophageal echo, cardioversion, and ablations.
                    <br/>
                      <a href="https://freida.ama-assn.org/specialty/interventional-cardiology-im">
                        {" "}
                        Read More from the American Medical Association{" "}
                      </a>
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <a href="_blank" onClick={(e) => e.preventDefault()}>
                        <img src={electrophysiology} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${electrophysiology})`,
                          opacity: "1",
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${electrophysiology})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <Danger>
                      <h6 className={classes.cardCategory}>
                        HEALTH
                      </h6>
                    </Danger>
                    <h3 className={classes.cardTitle}>
                      <a href="_blank" onClick={(e) => e.preventDefault()}>
                      Cardiac Electrophysiology
                      </a>
                    </h3>
                    <p className={classes.descriptionBlack}>
                    An electrophysiology (EP) study is a test performed to assess your heart's electrical system or activity and is used to diagnose abnormal heartbeats or arrhythmia.
                    The test is performed by inserting catheters and then wire electrodes, which measure electrical activity, through blood vessels that enter the heart. 
                    You can educate yourself and read more about Cardiac Electrophysiology at the trusted resources listed below. <br/>
                    <strong>In Office Testing</strong>: Treadmill stress test, nuclear treadmill stress test, echocardiograms, vascular ultrasound, vein mapping, vein ablation, and pacemaker checks.
                    <br/>
                      <a href="https://www.heart.org/en/health-topics/arrhythmia/symptoms-diagnosis--monitoring-of-arrhythmia/electrophysiology-studies-eps">
                        {" "}
                        Read More from the American Heart Association{" "}
                      </a>
                      <br/>
                      <a href="https://www.ucsfhealth.org/education/electrophysiology-procedure">
                        {" "}
                        Read More from University of California San Francisco Health{" "}
                      </a>
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
