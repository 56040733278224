import Header from "components/Header/Header.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
const useHeaderStyles = makeStyles(headersStyle);

export default function CustomHeader(props) {
  const headerClasses = useHeaderStyles();
    return (
        <Header
                brand="Cardiovascular Care, P.A."
                links={
                    <List className={headerClasses.list + " " + headerClasses.mlAuto}>
                    <ListItem className={headerClasses.listItem}>
                        <Button
                        className={headerClasses.navLink}
                        onClick={(e) => window.location.replace('/about-us')}
                        color="transparent"
                        >
                        About Us
                        </Button>
                    </ListItem>
                    <ListItem className={headerClasses.listItem}>
                        <Button
                        className={headerClasses.navLink}
                        onClick={(e) => window.location.replace('/physicians')}
                        color="transparent"
                        >
                        Physicians
                        </Button>
                    </ListItem>
                    <ListItem className={headerClasses.listItem}>
                        <Button
                        className={headerClasses.navLink}
                        onClick={(e) => window.location.replace('/services')}
                        color="transparent"
                        >
                        Services
                        </Button>
                    </ListItem>
                    <ListItem className={headerClasses.listItem}>
                        <Button
                        className={headerClasses.navLink}
                        onClick={(e) => window.location.replace('/patientinfo')}
                        color="transparent"
                        >
                        Patient Information
                        </Button>
                    </ListItem>
                    <ListItem className={headerClasses.listItem}>
                        <Button
                        className={headerClasses.navLink}
                        onClick={(e) => window.location.replace('/contact-us')}
                        color="transparent"
                        >
                        Contact Us
                        </Button>
                    </ListItem>
                    </List>
                }
                fixed
                color={props.color ? props.color : "transparent"}
                changeColorOnScroll={{
                height: 400,
                color: "danger",
                }}
            />
    )
}