import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

import HAmirani from "assets/img/faces/hamirani.png";
import Eid from "assets/img/faces/feid.jpg";
import Tyagi from "assets/img/faces/gtyagi.jpg";
import Mandanis from "assets/img/faces/cmandanis.jpg";



const useStyles = makeStyles(teamStyle);

export default function SectionPhysicians() {
  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Meet the Physicians</h2>
          <h5 className={classes.description}>
            We are proud to showcase our team of board-certified specialists, known for their extensive experience
            and skilled, patient-centered care. Our physicians are dedicated to providing compassionate and top-
            quality medical services. For any questions or referrals, please reach out to a member of our staff.
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <Card profile plain>
            <CardAvatar profile plain>
                <img src={HAmirani} alt="Dr. Hossein Amirani MD" className={classes.img} />
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Dr. Hossein Amirani, MD</h4>
              <h6 className={classes.textMuted}>Cardiologist & Ceo</h6>
              <p className={classes.cardDescription}>
                Dr. Amirani attended Wichita State University and the University of Kansas Medical School. 
                In October of 2005, Dr. Amirani opened Cardiovascular Care, P.A to serve the residents of Wichita and other surrounding areas.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={6} sm={6}>
          <Card profile plain>
            <CardAvatar profile plain>
                <img
                  src={Eid}
                  alt="Dr. Freddy Eid MD"
                  className={classes.img}
                />
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Dr. Freddy Eid, MD</h4>
              <h6 className={classes.textMuted}>Cardiologist</h6>
              <p className={classes.cardDescription}>
              Dr. Eid attended College Notre Dame de Louaize and Saint Joseph's University, Beirut. He joined us in June of 2013 and has made great contributions since. 
              He is also very proficient in French and Arabic.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={6} sm={6}>
          <Card profile plain>
            <CardAvatar profile plain>
                <img
                  src={Tyagi}
                  alt="Dr. Gaurav Tyagi MD"
                  className={classes.img}
                />
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Dr. Gaurav Tyagi, MD</h4>
              <h6 className={classes.textMuted}>Cardiologist</h6>
              <p className={classes.cardDescription}>
              Dr. Tyagi attended B J Medical College, Ahmedabad. He started with us in July of 2017. 
              He is also proficient in English and Hindi, with good working knowledge of Gujarati and Punjabi.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={6} sm={6}>
        <Card profile plain>
          <CardAvatar profile plain>
              <img
                src={Mandanis}
                alt="Dr. Christos Mandanis MD"
                className={classes.img}
              />
          </CardAvatar>
          <CardBody plain>
            <h4 className={classes.cardTitle}>Dr. Christos Mandanis, MD</h4>
            <h6 className={classes.textMuted}>Cardiologist</h6>
            <p className={classes.cardDescription}>
            Dr. Mandanis attended Aristotle University in Thessaloniki, Greece and completed his fellowship and residency at Tufts University in Boston, Massachusetts. 
            He specializes in cardiac electrophysiology, the study and treatment of abornmal heart rhythms in patients.
            </p>
          </CardBody>
        </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
