import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import { DP } from "assets/DataProvider.js";
import { CircularProgress } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput";
import { Tooltip } from "@material-ui/core";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import NavPills from "components/NavPills/NavPills";
import { Message } from "@material-ui/icons";
import Assignment from "@material-ui/icons/Assignment";
import CustomDialog from "./SectionDialog";

const useStyles = makeStyles(notificationsStyles);
const useStylesTable = makeStyles(style);

export default function SectionNotifications({notificationData, updateInMemory, userInfo, revalidate}) {
  const classes = useStyles();
  const activeToTabMap = {
      0 : 'CVC-Messages',
      1 : 'CVC-NewForm',
  }
  const tableClasses = useStylesTable();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [addEmail, setAddEmail] = React.useState('');
  const [notify, setNotify] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(-1);
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [subscriptionDelete, setSubscriptionDelete] = React.useState({
    patientEmail: '',
    key: '',
  });
//Special sort and search function for message search
const handleSubscriptionAdd = async () => {
    setLoading(true);
    const finalObj = {
      email: addEmail,
      topic: activeToTabMap[activeTab],
    }
    if(!addEmail.includes('@')) {
      // throw error
      alert ('Email to add is in an invalid format! Please make sure the email is correct.');
      setLoading(false);
      return;
    }
    try {
      const updRes = await DP.POSTWITHAUTHTOKEN(`notifications`, finalObj, userInfo.token)
      if(updRes.successMessage) {
        setNotify(false);
        setTimeout(() => setNotify({text: 'The subscription was successfully created!', color: 'success'}), 0)
        updateInMemory()
      }
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify(false);
        setTimeout(() => setNotify({text: e.toString(), color: 'danger'}), 0)
      }
    }
    finally {
        setLoading(false)
    }
  }

  const handleSubscriptionDelete = async () => {
    try {
        setLoading(true);
      const updRes = await DP.DELETEWITHAUTHTOKEN(`notifications/${subscriptionDelete.key}/${subscriptionDelete.patientEmail}`, userInfo.token)
      if(updRes.successMessage) {
        setNotify(false);
        setTimeout(() => setNotify({text: 'The subscription was successfully deleted!', color: 'success'}), 0);
        updateInMemory()
      }
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify(false);
        setTimeout(() => setNotify({text: e.toString(), color: 'danger'}), 0);
      }
    }
    finally {
        setLoading(false);
    }
  }


  const NotificationsSorted = (key, searchTerm = false) => {
      let notificationObjList = [];
      if(!notificationData) {
          return [];
      } else {
          notificationObjList = JSON.parse(JSON.stringify(notificationData));
      }
    //   Notification Keys
    let final = notificationObjList[key].Subscriptions.map((sub) => [sub.Endpoint, roundButtons(sub.Endpoint, key)]).sort((a,b) => a[0]-b[0]);
    if(searchTerm) {
        final = final.filter((element) => element[0].toLowerCase().includes(searchTerm));
    }
    // Now, let's sort.
    return final;
  }

  const handleSubscriptionDeleteReset = () => {
    setSubscriptionDelete({
      patientEmail: '',
      key: ''
    });
  }

  const roundButtons = (patientEmail, key) => [
    { color: "danger", icon: Close, tip: 'Unsubscribe', key: key, onClick: () => setSubscriptionDelete({patientEmail: patientEmail, key: key})},
  ].map((prop, key) => {
    return (
      <Tooltip
              id="tooltip-right"
              title={prop.tip}
              placement="right"
              key={key}
            >
      <Button round justIcon size="sm" color={prop.color} key={key} onClick={() => prop.onClick()}>
        <prop.icon />
      </Button>
      </Tooltip>
    );
  });
  return (
    <div>
      <h2 className={classes.title}>Event Notifications</h2>
      <h5 className={classes.title}> Setup email notifications when new messages come in.</h5>
      {!notificationData && (<CircularProgress />)}
      {notificationData && (
        <div>
          <GridContainer justify="center">
            <CustomDialog open={subscriptionDelete.patientEmail ? true : false} onConfirmation={handleSubscriptionDelete} onCancellation={handleSubscriptionDeleteReset} dialogContent={<h5>Are you sure you want to unsubscribe <strong>{subscriptionDelete.patientEmail}</strong> from <strong>{subscriptionDelete.key}</strong>?</h5>} />
            <GridItem xs={12} sm={12} md={6}> 
            <CustomInput
                        // white
                        inputRootCustomClasses={classes.inputRootCustomClasses}
                        formControlProps={{
                        className: classes.formControl,
                        fullWidth: true
                        }}
                        inputProps={{
                        placeholder: "Search",
                        inputProps: {
                            "aria-label": "Search",
                            className: classes.searchInput,
                        },
                        value: searchTerm,
                        onChange: (e) => {setSearchTerm((e.target.value))},
                        }}
                    />
            </GridItem>
            </GridContainer>
            <GridContainer justify="center">
            <NavPills
                color="primary"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4},
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                parentStateSet={setActiveTab}
                tabs={[
                  {
                    tabButton: "Messages",
                    tabIcon: Message,
                    tabContent: (
                        <GridContainer justify="center">
                        <Table
                              tableHead={[
                                "Email",
                                "Actions",
                              ]}
                              tableData={[
                                ...NotificationsSorted('CVC-Messages', searchTerm.toLowerCase()),
                              ]}
                              customCellClasses={[
                                tableClasses.textCenter,
                                tableClasses.textCenter,
                              ]}
                              customClassesForCells={[0, 1]}
                              customHeadCellClasses={[
                                tableClasses.textCenter,
                                tableClasses.textCenter,
                              ]}
                              customHeadClassesForCells={[0, 1]}
                            />
                      </GridContainer>
                    ),
                  },
                  {
                    tabButton: "New Patient Forms",
                    tabIcon: Assignment,
                    tabContent: (
                        <GridContainer justify="center">
                        <Table
                              tableHead={[
                                "Email",
                                "Actions",
                              ]}
                              tableData={[
                                ...NotificationsSorted('CVC-NewForm', searchTerm),
                              ]}
                              customCellClasses={[
                                tableClasses.textCenter,
                                tableClasses.textCenter,
                              ]}
                              customClassesForCells={[0, 1]}
                              customHeadCellClasses={[
                                tableClasses.textCenter,
                                tableClasses.textCenter,
                              ]}
                              customHeadClassesForCells={[0, 1]}
                            />
                      </GridContainer>
                    ),
                  },
                ]}
              />
      </GridContainer>
      <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}> 
            <CustomInput
                        // white
                        inputRootCustomClasses={classes.inputRootCustomClasses}
                        formControlProps={{
                        className: classes.formControl,
                        fullWidth: true
                        }}
                        inputProps={{
                        placeholder: "Email",
                        inputProps: {
                            "aria-label": "Email",
                            className: classes.searchInput,
                        },
                        value: addEmail,
                        onChange: (e) => {setAddEmail(e.target.value)},
                        }}
                    />
            {!loading ? (<>
                <Button color='primary' disabled={submitted} round onClick={handleSubscriptionAdd}>
                    Add Subscription
                </Button>
                {submitted && (<Button color='primary' round onClick={() => {setAddEmail(''); setSubmitted(false)}}>
                    Add Another Subscription
                </Button>)}
            </>) : <CircularProgress />}
            </GridItem>
            </GridContainer>
      {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      )}
    </div>
  );
}
