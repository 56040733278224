/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/bg7.jpg";
import CustomHeader from "components/Header/CustomHeader";
import CustomFooter from "components/Footer/CustomFooter";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { Checkbox, FormControl, FormControlLabel, Select, InputLabel, MenuItem } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import { DP } from "assets/DataProvider";


const useStylesBasic = makeStyles(basicsStyle);
const useStyles = makeStyles(signupPageStyle);

export default function NewFormPage({ ...rest }) {
  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = React.useState(false);
  const [numberOfContacts, setNumberOfContacts] = React.useState(0);
  const [checked, setChecked] = React.useState([]);
  const [mainInfoForm, setMainInfoForm] = React.useState({});
  const [emergencyContactForm, setEmergencyContactForm] = React.useState({leaveMessagesConsent: 'Yes', contactInfo: {}});
  const [permissionToDiscloseForm, setPermissionToDiscloseForm] = React.useState({});
  const [errors, setErrors] = React.useState([]);
  const classes = useStyles();
  const basicClasses = useStylesBasic();

  const handleChecked = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleMainInfoForm = (variableName, value) => {
    const mainInfoFormCopy = {...mainInfoForm};
    mainInfoFormCopy[variableName] = value;
    setMainInfoForm(mainInfoFormCopy);
  }

  const handleEmergencyContactInform = (variableName, value, contactInfoKey = false) => {
    const emergencyContactFormCopy = {...emergencyContactForm};
    if(contactInfoKey) {
      // Does contact info exist? If it does, attach, if not, we need to make it
      if(contactInfoKey in emergencyContactForm.contactInfo) {
        emergencyContactFormCopy.contactInfo[contactInfoKey][variableName] = value;
      } else {
        emergencyContactForm.contactInfo[contactInfoKey] = {}
        emergencyContactFormCopy.contactInfo[contactInfoKey][variableName] = value;
      }
    } else {
      emergencyContactFormCopy[variableName] = value;
    }
    setEmergencyContactForm(emergencyContactFormCopy);
  }

  const handlePermissionToDiscloseForm = (variableName, value) => {
    const permissionToDiscloseFormCopy = {...permissionToDiscloseForm};
    permissionToDiscloseFormCopy[variableName] = value;
    setPermissionToDiscloseForm(permissionToDiscloseFormCopy);
  }

  const submitForm = async () => {
    if(!ValidateForm()) {
      return;
    }
    setLoading(true);
    // We need to create the final JSON here
    const finalFormJSON = {
      firstName: mainInfoForm.firstName,
      middleInitial: mainInfoForm.middleInitial || '',
      lastName: mainInfoForm.lastName,
      dateOfBirth: mainInfoForm.dateOfBirth,
      phoneNumber: mainInfoForm.phoneNumber,
      address: mainInfoForm.address,
      city: mainInfoForm.city,
      state: mainInfoForm.state,
      zipCode: mainInfoForm.zipCode,
      patientEmail: mainInfoForm.patientEmail,
      leaveMessagesConsent: emergencyContactForm.leaveMessagesConsent,
      insuranceProvider: mainInfoForm.insuranceProvider,
      insurancePolicyHolder: mainInfoForm.insurancePolicyHolder,
      insurancePolicyNumber: mainInfoForm.insurancePolicyNumber
    }
    // Main info done, now to attach conditional info
    const contactInfoToSubmit = Object.keys(emergencyContactForm.contactInfo).sort((a,b) => a-b).slice(0, numberOfContacts);
    if(contactInfoToSubmit.length > 0) {
      finalFormJSON.contactInfo = [];
      for(let i = 0; i < contactInfoToSubmit.length; i++) {
        finalFormJSON.contactInfo.push(emergencyContactForm.contactInfo[contactInfoToSubmit[i]]);
      }
    }
    // Now we need to add our check boxes for permissionDisclosureOptions
    const checkedList = ['APTDATETIMES', 'TESTRESULTS', 'OTHERHEALTHINFO'];
    let submitList = [];
    for(let i = 0; i < checkedList.length; i++) {
      if(checked.includes(checkedList[i])) {
        submitList.push(checkedList[i]);
      }
    };
    if(submitList.length > 0) {
      // If we have something to submit, we can setup our object
      finalFormJSON.permissionDisclosureOptions = {
        permissionsAllowedToDisclose: submitList.join(', '),
        phoneNumber: permissionToDiscloseForm.phoneNumber
      }
      // Now we need to check for a few other things to add on
      if(checked.includes('SPOUSE')) {
        finalFormJSON.permissionDisclosureOptions.SpouseName = permissionToDiscloseForm.spouseName
      }
      if(checked.includes('FAMILYFRIEND')) {
        finalFormJSON.permissionDisclosureOptions.familyFriendName = permissionToDiscloseForm.familyFriendName
      }
      if(checked.includes('CHILD')) {
        finalFormJSON.permissionDisclosureOptions.childName = permissionToDiscloseForm.childName
      }
      if(checked.includes('OTHER')) {
        finalFormJSON.permissionDisclosureOptions.otherName = permissionToDiscloseForm.otherName
      }
    }
    try {
      const postRes = await DP.POST('newforms', finalFormJSON);
      if(postRes.successMessage) {
        setNotify({text: 'Your form was successfully sent!', color: 'success'})
      }
    } catch (e) {
      setNotify({text: 'There was an error sending your form. Please feel free to give us a call.', color: 'danger'})
    } finally {
      setLoading(false);
    }
  }

  const ValidateForm = () => {
    // Validate Main Info Form First
    setErrors([]);
    const errorsList = [];
    if(!mainInfoForm.firstName) {
      errorsList.push('FIRSTNAME');
    }
    if(!mainInfoForm.lastName) {
      errorsList.push('LASTNAME');
    }
    if(!mainInfoForm.dateOfBirth || mainInfoForm.dateOfBirth.split('/').length !== 3) {
      errorsList.push('DATEOFBIRTH');
    }
    if(!mainInfoForm.phoneNumber) {
      errorsList.push('PHONENUMBER');
    }
    if(!mainInfoForm.address) {
      errorsList.push('ADDRESS');
    }
    if(!mainInfoForm.city) {
      errorsList.push('CITY');
    }
    if(!mainInfoForm.state) {
      errorsList.push('STATE');
    }
    if(!mainInfoForm.zipCode) {
      errorsList.push('ZIPCODE');
    }
    if(!mainInfoForm.patientEmail || !mainInfoForm.patientEmail.includes('@')) {
      errorsList.push('EMAIL');
    }
    if(!mainInfoForm.insuranceProvider) {
      errorsList.push('IP');
    }
    if(!mainInfoForm.insurancePolicyHolder) {
      errorsList.push('IPH');
    }
    if(!mainInfoForm.insurancePolicyNumber) {
      errorsList.push('IPN');
    }
    // End validating Main Info Form

    // Begin validating emergency contact info
    // Array of keys to validate
    const contactInfoToValidate = Object.keys(emergencyContactForm.contactInfo).sort((a,b) => a-b).slice(0, numberOfContacts);
    for(let i = 0; i < contactInfoToValidate.length; i++) {
      if(!emergencyContactForm.contactInfo[contactInfoToValidate[i]].contactName) {
        errorsList.push(`${contactInfoToValidate[i]}NAME`);
      }
      if(!emergencyContactForm.contactInfo[contactInfoToValidate[i]].relationship) {
        errorsList.push(`${contactInfoToValidate[i]}RELATIONSHIP`);
      }
      if(!emergencyContactForm.contactInfo[contactInfoToValidate[i]].phoneNumber) {
        errorsList.push(`${contactInfoToValidate[i]}PHONENUMBER`);
      }
    }
    // End validating emergency contact info

    // Validate Permission Form
    // if any of the three are checked
    const checkedList = ['APTDATETIMES', 'TESTRESULTS', 'OTHERHEALTHINFO'];
    let foundChecked = false;
    for(let i = 0; i < checkedList.length; i++) {
      if(checked.includes(checkedList[i])) {
        foundChecked = true;
        break;
      }
    };
    // If any are checked, we need to check the info in the rest of the form
    if(foundChecked) {
      if(checked.includes('SPOUSE')) {
        if(!permissionToDiscloseForm.spouseName) {
          errorsList.push('SPOUSENAME');
        }
      }
      if(checked.includes('FAMILYFRIEND')) {
        if(!permissionToDiscloseForm.familyFriendName) {
          errorsList.push('FAMILYFRIENDNAME');
        }
      }
      if(checked.includes('CHILD')) {
        if(!permissionToDiscloseForm.childName) {
          errorsList.push('CHILDNAME');
        }
      }
      if(checked.includes('OTHER')) {
        if(!permissionToDiscloseForm.otherName) {
          errorsList.push('OTHERNAME');
        }
      }
      if(!permissionToDiscloseForm.phoneNumber) {
        errorsList.push('PERMISSIONPHONENUMBER');
      }
    }

    // Set the errorsList to drive icons
    if(errorsList.length > 0) {
      setErrors(errorsList);
      alert('There are errors with your form. Please check any fields for errors and ensure the email you entered is valid.')
      return false;
    }
    return true;
  }

  return (
    <div>
      <CustomHeader />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
        <Card className={classes.cardSignup}>
    <h2 className={classes.cardTitle}>New Patient Form</h2>
    <p className={classes.cardTitle}>Please fill out the fields below. This can help reduce the time you spend in the office.</p>
    <CardBody>
        <GridContainer justify="center">
        <GridItem cs={12} sm={9} md={12}>
          <form>
            <GridContainer justify="center">
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="First Name"
                  error={errors.indexOf('FIRSTNAME') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.firstName || '',
                    onChange: (e) => {handleMainInfoForm('firstName', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Middle Initial"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.middleInitial || '',
                    onChange: (e) => {handleMainInfoForm('middleInitial', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Last Name"
                  error={errors.indexOf('LASTNAME') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.lastName || '',
                    onChange: (e) => {handleMainInfoForm('lastName', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Date of Birth - MM/DD/YYYY"
                  error={errors.indexOf('DATEOFBIRTH') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.dateOfBirth || '',
                    onChange: (e) => {handleMainInfoForm('dateOfBirth', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Phone Number"
                  error={errors.indexOf('PHONENUMBER') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.phoneNumber || '',
                    onChange: (e) => {handleMainInfoForm('phoneNumber', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Address"
                  error={errors.indexOf('ADDRESS') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.address || '',
                    onChange: (e) => {handleMainInfoForm('address', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="City"
                  error={errors.indexOf('CITY') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.city || '',
                    onChange: (e) => {handleMainInfoForm('city', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="State"
                  error={errors.indexOf('STATE') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.state || '',
                    onChange: (e) => {handleMainInfoForm('state', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Zip Code"
                  error={errors.indexOf('ZIPCODE') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.zipCode || '',
                    onChange: (e) => {handleMainInfoForm('zipCode', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Insurance Provider"
                  error={errors.indexOf('IP') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.insuranceProvider || '',
                    onChange: (e) => {handleMainInfoForm('insuranceProvider', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Policy Holder"
                  error={errors.indexOf('IPH') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.insurancePolicyHolder || '',
                    onChange: (e) => {handleMainInfoForm('insurancePolicyHolder', e.target.value)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Policy Number"
                  error={errors.indexOf('IPN') !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: mainInfoForm.insurancePolicyNumber || '',
                    onChange: (e) => {handleMainInfoForm('insurancePolicyNumber', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Email Address"
                  error={errors.indexOf('EMAIL') !== -1}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: mainInfoForm.patientEmail || '',
                    onChange: (e) => {handleMainInfoForm('patientEmail', e.target.value)},
                  }}
                />
                </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      </CardBody>
      <h2 className={classes.cardTitle}>Emergency Contact / Disclosure List</h2>
      <p className={classes.cardTitle}>The people listed below have your permission to receive information from our staff regarding your health care and status, appointment, and/or billing.</p>
    <CardBody formHorizontal>
      <form className={classes.form}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={6}>
            <FormControl fullWidth className={basicClasses.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={basicClasses.selectLabel}
              >
              </InputLabel>
              <h6>I give permission to leave messages concerning my healthcare/appointments:</h6>
              <Select
                MenuProps={{
                  className: basicClasses.selectMenu,
                }}
                classes={{
                  select: basicClasses.select,
                }}
                value={emergencyContactForm.leaveMessagesConsent || ''}
                onChange={(e) => handleEmergencyContactInform('leaveMessagesConsent', e.target.value)}
              >
                <MenuItem
                  classes={{
                    root: basicClasses.selectMenuItem,
                  }}
                  value="Yes"
                >
                  Yes
                </MenuItem>
                <MenuItem
                  classes={{
                    root: basicClasses.selectMenuItem,
                    selected: basicClasses.selectMenuItemSelected,
                  }}
                  value="No"
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={6}>
            <FormControl fullWidth className={basicClasses.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={basicClasses.selectLabel}
              >
              </InputLabel>
              <h6>How many contacts would you like to list?</h6>
              <Select
                MenuProps={{
                  className: basicClasses.selectMenu,
                }}
                classes={{
                  select: basicClasses.select,
                }}
                value={numberOfContacts}
                onChange={(e) => setNumberOfContacts(e.target.value)}
              >
                <MenuItem
                  classes={{
                    root: basicClasses.selectMenuItem,
                  }}
                  value={0}
                >
                  0
                </MenuItem>
                <MenuItem
                  classes={{
                    root: basicClasses.selectMenuItem,
                    selected: basicClasses.selectMenuItemSelected,
                  }}
                  value={1}
                >
                  1
                </MenuItem>
                <MenuItem
                  classes={{
                    root: basicClasses.selectMenuItem,
                    selected: basicClasses.selectMenuItemSelected,
                  }}
                  value={2}
                >
                  2
                </MenuItem>
                <MenuItem
                  classes={{
                    root: basicClasses.selectMenuItem,
                    selected: basicClasses.selectMenuItemSelected,
                  }}
                  value={3}
                >
                  3
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        { [...Array(numberOfContacts).keys()].map((i) => {
          return (
            <GridContainer justify="center" key={`contactinfo${i}`}>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Name of Contact"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: emergencyContactForm.contactInfo && emergencyContactForm.contactInfo[`contactinfo${i}`] && emergencyContactForm.contactInfo[`contactinfo${i}`].contactName ? emergencyContactForm.contactInfo[`contactinfo${i}`].contactName : '',
                    onChange: (e) => {handleEmergencyContactInform('contactName', e.target.value, `contactinfo${i}`)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Relationship to Patient"
                  error={errors.indexOf(`contactinfo${i}RELATIONSHIP`) !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: emergencyContactForm.contactInfo && emergencyContactForm.contactInfo[`contactinfo${i}`] && emergencyContactForm.contactInfo[`contactinfo${i}`].relationship ? emergencyContactForm.contactInfo[`contactinfo${i}`].relationship : '',
                    onChange: (e) => {handleEmergencyContactInform('relationship', e.target.value, `contactinfo${i}`)},
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Phone Number"
                  error={errors.indexOf(`contactinfo${i}PHONENUMBER`) !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: emergencyContactForm.contactInfo && emergencyContactForm.contactInfo[`contactinfo${i}`] && emergencyContactForm.contactInfo[`contactinfo${i}`].phoneNumber ? emergencyContactForm.contactInfo[`contactinfo${i}`].phoneNumber : '',
                    onChange: (e) => {handleEmergencyContactInform('phoneNumber', e.target.value, `contactinfo${i}`)},
                  }}
                />
              </GridItem>
            </GridContainer>
          )
        }) }
      </form>
    </CardBody>
    {/* START THIRD FORM */}
    <h2 className={classes.cardTitle}>Permission to Disclose To Those Involved In My Care</h2>
    <p className={classes.cardTitle}>I hereby allow Cardiovascular Care, P.A. to disclose the following Protected Health Information:</p>
    <CardBody>
        <GridContainer justify="center">
        <GridItem cs={12} sm={9} md={9}>
          <form>
            <GridContainer justify="center">
              <GridItem xs={12} sm={3} md={3}>
              <div
                className={
                  basicClasses.checkboxAndRadio +
                  " " +
                  basicClasses.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleChecked('APTDATETIMES')}
                      checked={checked.indexOf('APTDATETIMES') === -1 ? false : true}
                      checkedIcon={<Check className={basicClasses.checkedIcon} />}
                      icon={<Check className={basicClasses.uncheckedIcon} />}
                      classes={{
                        checked: basicClasses.checked,
                        root: basicClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: basicClasses.label, root: basicClasses.labelRoot }}
                  label="Appointment Times and Dates"
                />
              </div>
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <div
                className={
                  basicClasses.checkboxAndRadio +
                  " " +
                  basicClasses.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleChecked('TESTRESULTS')}
                      checked={checked.indexOf('TESTRESULTS') === -1 ? false : true}
                      checkedIcon={<Check className={basicClasses.checkedIcon} />}
                      icon={<Check className={basicClasses.uncheckedIcon} />}
                      classes={{
                        checked: basicClasses.checked,
                        root: basicClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: basicClasses.label, root: basicClasses.labelRoot }}
                  label="Test Results"
                />
              </div>
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
              <div
                className={
                  basicClasses.checkboxAndRadio +
                  " " +
                  basicClasses.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleChecked('OTHERHEALTHINFO')}
                      checked={checked.indexOf('OTHERHEALTHINFO') === -1 ? false : true}
                      checkedIcon={<Check className={basicClasses.checkedIcon} />}
                      icon={<Check className={basicClasses.uncheckedIcon} />}
                      classes={{
                        checked: basicClasses.checked,
                        root: basicClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: basicClasses.label, root: basicClasses.labelRoot }}
                  label="Other Health Information"
                />
              </div>
              </GridItem>
            </GridContainer>
            <p className={classes.cardTitle}>to the following people via phone because they are involved with my healthcare or payment:</p>
            <GridContainer justify="center">
              <GridItem xs={12} sm={4} md={4}>
              <div
                className={
                  basicClasses.checkboxAndRadio +
                  " " +
                  basicClasses.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleChecked('SPOUSE')}
                      checked={checked.indexOf('SPOUSE') === -1 ? false : true}
                      checkedIcon={<Check className={basicClasses.checkedIcon} />}
                      icon={<Check className={basicClasses.uncheckedIcon} />}
                      classes={{
                        checked: basicClasses.checked,
                        root: basicClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: basicClasses.label, root: basicClasses.labelRoot }}
                  label="Spouse"
                />
              </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                  labelText="Name"
                  error={errors.indexOf(`SPOUSENAME`) !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: permissionToDiscloseForm.spouseName || '',
                    onChange: (e) => {handlePermissionToDiscloseForm('spouseName', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={4} md={4}>
              <div
                className={
                  basicClasses.checkboxAndRadio +
                  " " +
                  basicClasses.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleChecked('FAMILYFRIEND')}
                      checked={checked.indexOf('FAMILYFRIEND') === -1 ? false : true}
                      checkedIcon={<Check className={basicClasses.checkedIcon} />}
                      icon={<Check className={basicClasses.uncheckedIcon} />}
                      classes={{
                        checked: basicClasses.checked,
                        root: basicClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: basicClasses.label, root: basicClasses.labelRoot }}
                  label="Family Friend"
                />
              </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                  labelText="Name"
                  error={errors.indexOf(`FAMILYFRIENDNAME`) !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: permissionToDiscloseForm.familyFriendName || '',
                    onChange: (e) => {handlePermissionToDiscloseForm('familyFriendName', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={4} md={4}>
              <div
                className={
                  basicClasses.checkboxAndRadio +
                  " " +
                  basicClasses.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleChecked('CHILD')}
                      checked={checked.indexOf('CHILD') === -1 ? false : true}
                      checkedIcon={<Check className={basicClasses.checkedIcon} />}
                      icon={<Check className={basicClasses.uncheckedIcon} />}
                      classes={{
                        checked: basicClasses.checked,
                        root: basicClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: basicClasses.label, root: basicClasses.labelRoot }}
                  label="Child"
                />
              </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                  labelText="Name"
                  error={errors.indexOf(`CHILDNAME`) !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: permissionToDiscloseForm.childName || '',
                    onChange: (e) => {handlePermissionToDiscloseForm('childName', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={4} md={4}>
              <div
                className={
                  basicClasses.checkboxAndRadio +
                  " " +
                  basicClasses.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleChecked('OTHER')}
                      checked={checked.indexOf('OTHER') === -1 ? false : true}
                      checkedIcon={<Check className={basicClasses.checkedIcon} />}
                      icon={<Check className={basicClasses.uncheckedIcon} />}
                      classes={{
                        checked: basicClasses.checked,
                        root: basicClasses.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: basicClasses.label, root: basicClasses.labelRoot }}
                  label="Other"
                />
              </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                  labelText="Name"
                  error={errors.indexOf(`OTHERNAME`) !== -1}
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    value: permissionToDiscloseForm.otherName || '',
                    onChange: (e) => {handlePermissionToDiscloseForm('otherName', e.target.value)},
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Phone Number"
                  error={errors.indexOf(`PERMISSIONPHONENUMBER`) !== -1}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: permissionToDiscloseForm.phoneNumber || '',
                    onChange: (e) => {handlePermissionToDiscloseForm('phoneNumber', e.target.value)},
                  }}
                />
                </GridItem>
            </GridContainer>
            <hr/>
            <GridContainer justify="center">
            <GridItem
                xs={12}
                sm={4}
                md={4}
                className={classes.mrAuto + " " + classes.mlAuto}
              >
                <Button color="primary" round disabled={loading || notify && notify.color === 'success'} onClick={() => submitForm()}>Submit Form</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
      </CardBody>
      {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      {loading && (<CustomLinearProgress color='primary'/>)}
      </Card>
        </div>
        <CustomFooter />
      </div>
    </div>
  );
}
