import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  
Transition.displayName = "Transition";

const useStyles = makeStyles(javascriptStyles);

export default function CustomDialog(props) {
    const { closeText, confirmText, open, dialogContent, onConfirmation, onCancellation} = props;
    const [smallModal, setSmallModal] = React.useState(open);
    const classes = useStyles();
    React.useEffect(() => {
        setSmallModal(open)
    }, [open]);
    const handleConfirmation = () => {
        if(typeof onConfirmation === 'function') {
            onConfirmation();
        }
        setSmallModal(false);
    }
    const handleCancellation = () => {
        if(typeof onCancellation === 'function') {
            onCancellation();
        }
        setSmallModal(false);
    }
    return (
        <>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal,
                }}
                open={smallModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleCancellation()}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogTitle
                    id="small-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => handleCancellation()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="small-modal-slide-description"
                    className={classes.modalBody + " " + classes.modalSmallBody}
                >
                    {dialogContent}
                </DialogContent>
                <DialogActions
                    className={
                        classes.modalFooter + " " + classes.modalFooterCenter
                    }
                >
                    <Button
                        onClick={() => handleCancellation()}
                        link
                        className={classes.modalSmallFooterFirstButton}
                    >
                        { closeText }
                    </Button>
                    <Button
                        onClick={() => handleConfirmation()}
                        color="success"
                        simple
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        { confirmText }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

CustomDialog.defaultProps = {
    closeText: 'Cancel',
    confirmText: 'Confirm',
}

CustomDialog.propTypes = {
    dialogContent: PropTypes.node.isRequired,
    onConfirmation: PropTypes.func,
    onCancellation: PropTypes.func,
    open: PropTypes.bool.isRequired,
    closeText: PropTypes.string,
    confirmText: PropTypes.string,
};
