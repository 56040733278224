/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import CustomHeader from "components/Header/CustomHeader";
import CustomFooter from "components/Footer/CustomFooter";
import CustomInput from "components/CustomInput/CustomInput";
import { CircularProgress } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import Check from "@material-ui/icons/Check";
import { DP } from "assets/DataProvider";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function UnsubscribePage({ ...rest }) {
  const [email, setEmail] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = React.useState(undefined);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  const handleUnsubscribe = async () => {
    setLoading(true);
    if(!email.includes('@')) {
      // throw error
      alert ('Email Address is in an invalid format! Please make sure the email is correct.');
      setLoading(false);
      return;
    }
    try {
      const updRes = await DP.DELETE(`email/${email}`)
      if(updRes.successMessage) {
        setNotify(false);
        setTimeout(() => setNotify({text: 'The subscription was successfully removed!', color: 'success'}), 0)
        setSubmitted(true);
      }
    } catch (e) {
      console.error(e);
      setNotify(false);
      setTimeout(() => setNotify({text: e.toString(), color: 'danger'}), 0)
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 5000)
    }
    finally {
        setLoading(false)
    }
  }
  return (
    <div>
      <CustomHeader />
      <Parallax
        image={require("assets/img/city.jpg").default}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h3 className={classes.title}>Unsubscribe Your Email</h3>
              <h5 className={classes.title}>Please enter your email below, and click the unsubscribe button.</h5>
            </GridItem>
            {!loading && (<GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Email Address"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: email,
                  onChange: (e) => { setEmail(e.target.value) },
                  disabled: submitted
                }}
              />
            </GridItem>)}
          </GridContainer>
          <GridContainer justify="center" className={classes.buttonAlign}>
              {!loading ? (<Button round color="primary" disabled={submitted} onClick={handleUnsubscribe}>
                Unsubscribe
              </Button>) : <CircularProgress />}
          </GridContainer>
          <Clearfix />
        </div>
        {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      <CustomFooter />
    </div>
  );
}
