/*!

=========================================================
* Material Kit PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import PhysiciansPage from "views/PhysiciansPage/PhysiciansPage.js";
import ServicesPage from "views/ServicesPage/ServicesPage.js";
import PatientInfoPage from "views/PatientInfoPage/PatientInfoPage.js";
import CustomContactUsPage from "views/ContactUsPage/CustomContactUsPage";
import AuthCodePage from "views/AuthCodePage/AuthCodePage";
import NewFormPage from "views/NewFormPage/NewFormPage";
import ManagementPortalPage from "views/ManagementPortalPage/ManagementPortalPage";
import UnsubscribePage from "views/UnsubscribePage/UnsubscibePage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/physicians" component={PhysiciansPage} />
      <Route path='/services' component={ServicesPage} />
      <Route path="/patientinfo" component={PatientInfoPage} />
      <Route path="/contact-us" component={CustomContactUsPage} />
      <Route path='/code' component={AuthCodePage} />
      <Route path='/newpatientform' component={NewFormPage} />
      <Route path='/managementportal' component={ManagementPortalPage} />
      <Route path='/unsubscribe' component={UnsubscribePage} />
      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
