import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import SnackbarContent from "components/Snackbar/SnackbarContent";

import { DP } from "assets/DataProvider.js";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import wichita from "assets/img/wichita-ks.jpg";
import Check from "@material-ui/icons/Check";
const useStyles = makeStyles(contactsStyle);

export default function SectionLandingContact({ ...rest }) {
  const [loading, setLoading] = React.useState(false);
  // make indivial state objects for user
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phNo, setPhNo] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [errors, setErrors] = React.useState('');
  const [notify, setNotify] = React.useState(false);

  const ValidateFormFields = () => {
    // firts, reset errors so we can set new one
    let errorCount = 0;
    let tempError = '';
    setErrors('');
    if(!firstName) {
      tempError += 'FIRSTNAME';
      errorCount = errorCount + 1;
    };
    if(!lastName) {
      tempError += 'LASTNAME';
      errorCount = errorCount + 1;
    };
    if(!email || !email.includes('@')) {
      tempError += 'EMAIL';
      errorCount = errorCount + 1;
    }
    if(!message) {
      tempError += 'MESSAGE';
      errorCount = errorCount + 1;
    }
    if (errorCount > 0) {
      setErrors(tempError);
      alert(`There were ${errorCount} issues with your form submission. Please make sure you haven't forgotten to fill out any fields and entered a valid email address.`);
      return false;
    } else {
      return true;
    }
  }
  const handleFormSubmit = async () => {
    // handle form submission
    // We need validate
    if(ValidateFormFields()) {
      // We can put together our JSON to submit the form
      setLoading(true);
      const finalJSON = {
        firstName: firstName,
        lastName: lastName,
        patientEmail: email,
        messageText: message,
        phoneNumber: phNo ? phNo : -1,
      }
      try {
        const postRes = await DP.POST('messages', finalJSON);
        if(postRes.successMessage) {
          setNotify({text: 'Your message was successfully sent!', color: 'success'})
        }
      } catch (e) {
        setNotify({text: 'There was an error sending your message. Please feel free to give us a call.', color: 'danger'})
      } finally {
        setLoading(false);
      }
    }
  }
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 1 START */}
      <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${wichita})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              <h2 className={classes.title}>Get in Touch</h2>
              <h5 className={classes.description}>
                Need more information? Please fill out the form below and include details about any symptoms, existing
                conditions, or concerns, and preferred appointment dates. A member of the Cardiovascular Care team
                will reach out to you shortly. Please note that medical questions cannot be addressed through this form.
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="Our Offices"
                description={
                  <>
                      925 N Hillside St
                      <br /> <strong>Wichita</strong>, KS
                      <br /> 67214
                      <br /> Mon - Fri, 8:00 AM - 5:00 PM
                      <br/><br/>
                      715 Medical Center Dr, Suite 100
                      <br /> <strong>Newton</strong>, KS
                      <br /> 67114
                      <br /> Mon - Fri, 8:00 AM - 5:00 PM
                  </>
                }
                icon={PinDrop}
                color="white"
              />
              <InfoArea
                className={classes.infoArea}
                title="Give us a ring"
                description={
                  <span>
                    Wichita: (316) 616-3333
                    <br /> Newton: (316) 804-4670
                    <br /> Mon - Fri, 8:00 AM - 5:00 PM
                  </span>
                }
                icon={Phone}
                color="white"
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    color="primary"
                    className={classes.textCenter}
                  >
                    <h4 className={classes.cardTitle}>Contact Us</h4>
                  </CardHeader>
                  <CardBody>
                    {loading && (<CustomLinearProgress color='primary'/>)}
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="First Name"
                          id="first"
                          error={errors.includes('FIRSTNAME')}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: firstName,
                            onChange: (e) => {setFirstName(e.target.value)},
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="last"
                          error={errors.includes('LASTNAME')}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: lastName,
                            onChange: (e) => {setLastName(e.target.value)},
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      labelText="Email Address"
                      id="email-address"
                      error={errors.includes('EMAIL')}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: email,
                        onChange: (e) => {setEmail(e.target.value ? e.target.value.replace(/ /g, '') : e.target.value)},
                      }}
                    />
                    <CustomInput
                      labelText="Phone Number"
                      id="phone-number"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: phNo,
                        onChange: (e) => {setPhNo(e.target.value)},
                      }}
                    />
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      error={errors.includes('MESSAGE')}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value: message,
                        onChange: (e) => {setMessage(e.target.value)},
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <Button color={notify && notify.color === "success" ? "success" : "primary"} className={classes.pullRight} disabled={loading || (notify && notify.color === "success")} onClick={() => handleFormSubmit()}>
                      {notify && notify.color === "success" ? "Message Sent" : "Send Message"}
                    </Button>
                  </CardFooter>
                  {notify && (<SnackbarContent 
                    message={notify.text}
                    color={notify.color}
                    icon={notify.color === 'success' ? Check : 'info_outline'}
                    />)}
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Contact us 1 END */}
      
    </div>
  );
}