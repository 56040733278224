/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionManagementPills from "./Sections/SectionManagementPills.js";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import CustomFooter from "components/Footer/CustomFooter.js";
import CustomHeader from "components/Header/CustomHeader.js";
import { SiteConfig } from "assets/SiteConfig.js";
import { DP } from "assets/DataProvider.js";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(blogPostsPageStyle);

export default function ManagementPortalPage() {
  const [userInfo, setUserInfo] = React.useState({email:'1'});
  const [loading, setLoading] = React.useState(false);
  const HandleTokenValidation = async () => {
    // First we need to see if there is a token in storage.
    const storedToken = window.localStorage.getItem('authToken');
    if(storedToken) {
      const storedTokenJSON = JSON.parse(storedToken);
      if(!storedTokenJSON.id_token) {
        window.location.replace(SiteConfig.SignInURL);
        return;
      }
      // validate it
      try {
        const validateRes = await DP.GETWITHAUTHTOKEN('validate', storedTokenJSON.id_token);
        setUserInfo({
          email: validateRes.email,
          firstName: validateRes.given_name,
          lastName: validateRes.family_name,
          token: storedTokenJSON.id_token,
        })
        return true;
      } catch (e) {
        console.error(e);
        if(e.toString().includes('401')) {
          // retauth needed
          window.location.replace(SiteConfig.SignInURL);
          return;
        }
      }
    } else {
      // no token? redirect
      window.location.replace(SiteConfig.SignInURL);
      return;
    }
  }
  React.useEffect(async () => {
    setLoading(true);
    const validationRes = await HandleTokenValidation();
    // if(validationRes) {
    //   // Get patient info
    // }
    setLoading(false);
  }, []);
  const classes = useStyles();
  return (
    <div>
      <CustomHeader/>
      <Parallax
        image={require("assets/img/bg10.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
              <h2 className={classes.title}>
                Management Portal
              </h2>
              {userInfo.email && (<h4 className={classes.title}>Welcome {userInfo.firstName + ' ' + userInfo.lastName}</h4>)}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      {userInfo.email && (
      <div className={classes.main}>
        <div className={classes.container}>
          {userInfo.email ? (
            <>
              <SectionManagementPills userInfo={userInfo} revalidate={HandleTokenValidation}/>
            </>
          ) : (
            <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
              <CircularProgress/>
              </GridItem>
            </GridContainer>
            
          )}
        </div>
      </div>
      )}
      <CustomFooter/>
    </div>
  );
}
