import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Print, Send } from "@material-ui/icons";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import { Grid } from "@material-ui/core";
import pillsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js";
// Form
import MPITestingForm from "assets/forms/mpitesting.pdf";
import NewPatientForm from "assets/forms/newpatient.pdf";
import MPINoShow from "assets/forms/noshow.pdf";
import Arteriogram from "assets/forms/arteriogram.pdf";
import Ablation from "assets/forms/ablation.pdf";
import HeartCath from "assets/forms/heartcath.pdf";
import Linqreveal from "assets/forms/linqreveal.pdf";
import Pacemaker from "assets/forms/pacemaker.pdf";
import PeripheralCatherization from "assets/forms/peripheralcatherization.pdf";
import TiltTable from "assets/forms/tilttable.pdf";
import TransesophagealEcho from "assets/forms/transesophagaelecho.pdf";

const useStyles = makeStyles(pillsStyle);

export default function SectionPatientInfoPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                horizontal={{
                  tabsGrid: { xs: 12, sm: 3, md: 3 },
                  contentGrid: { xs: 12, sm: 9, md: 9 },
                }}
                color="danger"
                tabs={[
                  {
                    tabButton: "Patient Forms",
                    tabContent: (
                      <span>
                        <h2>
                          New Patient
                        </h2>
                        <hr />
                        <p>
                          If you are a new patient, feel free to print or submit electronically ahead of the time the new patient forms 
                          to help reduce the amount of time you have to wait in the office. Below, you can choose whether you'd like to print the form out
                          and fill in by hand, or you can fill it in electronically and our staff will be sent a copy that they can reference during your appointment.
                        </p>
                        <Grid container justify="center" className={classes.centerButtons}>
                          <Button
                          onClick={(e) => window.open(NewPatientForm, '_blank')}
                          color="danger"
                          >
                            <Print/>
                            PRINT
                          </Button>
                          <br/>
                          <Button
                          onClick={(e) => window.location.replace('/newpatientform')}
                          color="danger"
                          >
                          <Send/>
                          SUBMIT ELECTRONICALLY
                          </Button>
                        </Grid>
                        
                      </span>
                    ),
                  },
                  {
                    tabButton: "Insurance",
                    tabContent: (
                      <span>
                        <p>
                        <h3>
                          Insurance
                        </h3>
                        <hr />
                        <p>
                          We accept most common and popular health insurance providers. To find out if we support yours, please contact us through the <a href='/contact-us'>Contact Us'</a> tab at the top of this page. 
                        </p>
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Policies",
                    tabContent: (
                      <span>
                        <h3>Nuclear Stress Test No Show Policy</h3>
                        <hr/>
                        <p>
                          Please click <a href={MPINoShow} target="_blank">here</a> to read our nuclear stress test no show policy.
                        </p>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Pre-Testing Preparation",
                    tabContent: (
                      <span>
                        <h3>
                          Ablation
                        </h3>
                        <p>
                          To read about ablation preparation, please click <a href={Ablation} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                          Arteriogram
                        </h3>
                        <p>
                          To read about arteriogram preparation, please click <a href={Arteriogram} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                        Echocardiogram 
                        </h3>
                        <p>
                          There is no preparation needed in order to have an echocardiogram performed.
                        </p>
                        <hr />
                        <h3>
                          Heart Catherization
                        </h3>
                        <p>
                          To read about heart catherization preparation, please click <a href={HeartCath} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                          Linq/Reveal Implant
                        </h3>
                        <p>
                          To read about linq/reveal implant preparation, please click <a href={Linqreveal} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                          MPI Testing (Nuclear Stress Test)
                        </h3>
                        <p>
                          To read about MPI testing, please click <a href={MPITestingForm} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                          Pacemaker Insertion
                        </h3>
                        <p>
                          To read about pacemaker insertion preparation, please click <a href={Pacemaker} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                          Peripheral Catherization
                        </h3>
                        <p>
                          To read about peripheral catherization preparation, please click <a href={PeripheralCatherization} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                          Tilt Table
                        </h3>
                        <p>
                          To read about tilt table preparation, please click <a href={TiltTable} target="_blank">here.</a>
                        </p>
                        <hr />
                        <h3>
                          Transesophageal Echo
                        </h3>
                        <p>
                          To read about transesophageal echo preparation, please click <a href={TransesophagealEcho} target="_blank">here.</a>
                        </p>
                        <hr />
                        {/* <h3>
                          Vein procedures
                        </h3>
                        <p>
                          TO DO
                        </p> */}
                      </span>
                    ),
                  },
                  {
                    tabButton: "Hospital Affiliation",
                    tabContent: (
                      <span>
                        <h2>
                          Hospital Affiliations
                        </h2>
                        <hr />
                        <p>
                          We pair with a number of hospitals in the Kansas area. <br/>
                          <a target="_blank" href="https://wesleymc.com/" >Wesley Healthcare</a><br/>
                          <a target="_blank" href="https://www.viachristi.org/">Ascension Via Christi</a><br/>
                          <a target="_blank" href="https://www.selectspecialtyhospitals.com/">Select Speciality Hospitals</a><br/>
                          <a target="_blank" href="https://www.selectspecialtyhospitals.com/">Newton Medical Center</a><br/>
                          <a target="_blank" href="https://www.slhmarion.org/">St Luke Hospital</a>
                        </p>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
