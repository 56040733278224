import Footer from "./Footer"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import { SiteConfig } from "assets/SiteConfig";
const useStyles = makeStyles(presentationStyle);


export default function CustomFooter(props) {
  const classes = useStyles();
    return (
        <Footer
        theme="white"
        content={
          <div>
            <div className={classes.left}>
              <a
                // href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkpr-presentation"
                // target="_blank"
                className={classes.footerBrand}
              >
                Cardiovascular Care P.A.
              </a>
            </div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/about-us"
                    // target="_blank"
                    className={classes.block}

                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href={SiteConfig.SignInURL}
                    // target="_blank"
                    className={classes.block}
                  >
                    Sign In
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              <h6>Created by Armaan Amirani</h6>
            </div>
          </div>
        }
      />
    )
}