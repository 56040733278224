/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
// core components
import CustomHeader from "components/Header/CustomHeader";
import CustomFooter from "components/Footer/CustomFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SectionMapPills from "./Sections/SectionMapPills";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import Check from "@material-ui/icons/Check";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice";
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import { DP } from "assets/DataProvider";
const useStyles = makeStyles(contactUsStyle);

export default function CustomContactUsPage() {
  const [isNewton, setIsNewton] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  // make indivial state objects for user
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phNo, setPhNo] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [errors, setErrors] = React.useState('');
  const [notify, setNotify] = React.useState(false);
  const mapRef = React.useRef(null)
  const ValidateFormFields = () => {
    // firts, reset errors so we can set new one
    let errorCount = 0;
    let tempError = '';
    setErrors('');
    if(!firstName) {
      tempError += 'FIRSTNAME';
      errorCount = errorCount + 1;
    };
    if(!lastName) {
      tempError += 'LASTNAME';
      errorCount = errorCount + 1;
    };
    if(!email || !email.includes('@')) {
      tempError += 'EMAIL';
      errorCount = errorCount + 1;
    }
    if(!message) {
      tempError += 'MESSAGE';
      errorCount = errorCount + 1;
    }
    if (errorCount > 0) {
      setErrors(tempError);
      alert(`There were ${errorCount} issues with your form submission. Please make sure you haven't forgotten to fill out any fields and entered a valid email address.`);
      return false;
    } else {
      return true;
    }
  }
  const handleFormSubmit = async () => {
    // handle form submission
    // We need validate
    if(ValidateFormFields()) {
      // We can put together our JSON to submit the form
      setLoading(true);
      const finalJSON = {
        firstName: firstName,
        lastName: lastName,
        patientEmail: email,
        messageText: message,
        phoneNumber: phNo ? phNo : -1
      }
      try {
        const postRes = await DP.POST('messages', finalJSON);
        if(postRes.successMessage) {
          setNotify({text: 'Your message was successfully sent!', color: 'success'})
        }
      } catch (e) {
        setNotify({text: 'There was an error sending your message. Please feel free to give us a call.', color: 'danger'})
      } finally {
        setLoading(false);
      }
    }
  }
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let coords = isNewton ? {
      lat: "38.0219042724439",
      lng: "-97.33294517889912"
    } : {
      lat: "37.699937918536556",
      lng: "-97.29939017863812"
    };
    const myLatlng = new google.maps.LatLng(coords.lat, coords.lng);
    const mapOptions = {
      zoom: 15,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      animation: google.maps.Animation.DROP,
      title: "Cardiovascular Care P.A",
    });

    const contentString =
      '<div class="info-window-content"><h2>Cardiovascular Care P.A</h2>' +
      "<p>925 N Hillside St. Wichita, KS 67206</p></div>";

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });
  }, [isNewton]);
  const CustomSkinMap = React.memo(props => {
    return (
      <>
        <div
          style={{ height: `100%`, borderRadius: "6px", overflow: "hidden" }}
          ref={props.mapRef}
        ></div>
      </>
    );
  }, isNewton);

  const classes = useStyles();
  return (
    <div>
      <CustomHeader color="white"/>
      <div className={classes.bigMap}>
        <CustomSkinMap mapRef={mapRef}/>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <SectionMapPills parentStateSet={setIsNewton}/>
            <hr />
            <h2 className={classes.title}>Send us a message</h2>
            {loading && (<CustomLinearProgress color='primary'/>)}
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  Need more information? Please fill out the form below and include details about any symptoms, existing
                  conditions, or concerns, and preferred appointment dates. A member of the Cardiovascular Care team
                  will reach out to you shortly. Please note that medical questions cannot be addressed through this form.
                  <br />
                  <br />
                </p>
                <form>
                <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="First Name"
                          id="first"
                          error={errors.includes('FIRSTNAME')}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: firstName,
                            onChange: (e) => {setFirstName(e.target.value)},
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="last"
                          error={errors.includes('LASTNAME')}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: lastName,
                            onChange: (e) => {setLastName(e.target.value)},
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      labelText="Email Address"
                      id="email-address"
                      error={errors.includes('EMAIL')}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: email,
                        onChange: (e) => {setEmail(e.target.value ? e.target.value.replace(/ /g, '') : e.target.value)},
                      }}
                    />
                    <CustomInput
                      labelText="Phone Number"
                      id="phone-number"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: phNo,
                        onChange: (e) => {setPhNo(e.target.value)},
                      }}
                    />
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      error={errors.includes('MESSAGE')}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value: message,
                        onChange: (e) => {setMessage(e.target.value)},
                      }}
                    />
                  <div className={classes.textCenter}>
                    <Button color={notify && notify.color === "success" ? notify.color : "primary"} round disabled={loading || notify && notify.color === "success"} onClick={() => handleFormSubmit()}>
                      {notify && notify.color === "success" ? "Message Sent!" : "Send Message"}
                    </Button>
                  </div>
                </form>
              </GridItem>
              <GridItem md={4} sm={4} className={classes.mlAuto}>
              <InfoArea
                className={classes.info}
                title="Our Offices"
                description={
                  <>
                      925 N Hillside St
                      <br /> <strong>Wichita</strong>, KS
                      <br /> 67214
                      <br /> Mon - Fri, 8:00 AM - 5:00 PM
                      <br/><br/>
                      715 Medical Center Dr, Suite 100
                      <br /> <strong>Newton</strong>, KS
                      <br /> 67114
                      <br /> Mon - Fri, 8:00 AM - 5:00 PM
                  </>
                }
                icon={PinDrop}
                color="black"
              />
              <InfoArea
                className={classes.info}
                title="Give us a ring"
                description={
                  <span>
                    Wichita: (316) 616-3333
                    <br /> Newton: (316) 804-4670
                    <br /> Mon - Fri, 8:00 AM - 5:00 PM
                  </span>
                }
                icon={Phone}
                color="black"
              />
              </GridItem>
            </GridContainer>
            <hr />
            <SectionOffice />
          </div>
        </div>
        {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      <CustomFooter/>
    </div>
  );
}
