/*eslint-disable*/
import React, { useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CustomHeader from "components/Header/CustomHeader.js"
import Parallax from "components/Parallax/Parallax.js";
import CustomFooter from "components/Footer/CustomFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SectionBanner from "./Sections/SectionBanner";
// sections for this page
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import SectionLandingContact from "./Sections/SectionLandingContact";
import SectionLetsTalkHealth from "views/PatientInfoPage/Sections/SectionLetsTalkHealth";

// assets
import cvcLogo from "assets/img/cvclogotrans.png";
// dataprovider
import { DP } from "assets/DataProvider.js";
const useStyles = makeStyles(presentationStyle);


export default function PresentationPage() {

  const [bannerOptions, setBannerOptions] = React.useState({});

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  React.useEffect(async () => {
    // Getting results for the banner config
    const result = await DP.GET('config/Banner')
    if (result) {
      if(result.Value === 'disabled') {
        return;
      }
      setBannerOptions({
        text: result.Value,
        style: result && result && result.Options && result.Options.Style ? result.Options.Style : 'Info',
      })
    }
  }, [])

  const classes = useStyles();
  // Functions
  const myRef = useRef(null);
  const executeScroll = () => {
    if(myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  // Functions
  return (
    <div>
      {/* HEADER AND LINKS */}
      <CustomHeader executeScroll={executeScroll}/>
      {bannerOptions.text && (
        <SectionBanner text={bannerOptions.text} color={bannerOptions.style}/>
      )}
      {/* IMAGE AND FRONT COVER */}
      <Parallax
        image={require("assets/img/heart.jpg").default}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
              <img className={classes.logo} src={cvcLogo}></img>
                <h1>
                Cardiovascular Care, P.A.
                </h1>
                <h4 className={classes.title}>
                  Providing top quality healthcare with compassion to patients<br/>in the Wichita and Newton, Kansas areas since 2005.
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionLetsTalkHealth />
        <div ref={myRef}>
        <SectionLandingContact />
        </div>
      </div>
      {/* FOOTER STARTS HERE */}
      <CustomFooter />
    </div>
  );
}
