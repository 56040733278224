import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import notificationsStyles from "assets/jss/material-kit-pro-react/views/componentsSections/notificationsStyles.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import SectionBanner from "../../PresentationPage/Sections/SectionBanner.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { DP } from "assets/DataProvider.js";
import { CircularProgress } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

const useStylesBasic = makeStyles(basicsStyle);
const useStyles = makeStyles(notificationsStyles);

export default function SectionBannerConfig({userInfo, revalidate}) {
  const classes = useStyles();
  const basicClasses = useStylesBasic();
  const [bannerText, setBannerText] = React.useState('');
  const [bannerStyle, setBannerStyle] = React.useState('info');
  const [initialLoaded, setInitialLoaded] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [notify, setNotify] = React.useState(false);
  const [refreshBanner, setRefreshBanner] = React.useState(true);
  const bannerStyleList = ["info", "success", "warning", "danger", "primary"];

  const handleBannerSubmission = async () => {
    // validate quickly
    const finalPutObj = {
      "Value" : bannerText,
      "Options": {
          "Style" : bannerStyle
      }
    }
    // now update
    try {
      setInitialLoaded(false);
      const updRes = await DP.PUTWITHAUTHTOKEN('config/Banner', finalPutObj, userInfo.token)
      if(updRes.successMessage) {
        setNotify({text: 'Your banner was successfully updated!', color: 'success'})
      }
      setSubmitted(true);
    } catch (e) {
      console.error(e);
      if(e.toString().includes('401')) {
        console.error(e);
        revalidate();
      } else {
        setNotify({text: e.toString(), color: 'danger'})
      }
      setInitialLoaded(true);
    }
    setInitialLoaded(true);
  }

  React.useEffect(async () => {
    try {
      const bannerConfig = await DP.GET('config/Banner');
      if(bannerConfig && bannerConfig.Options && bannerConfig.Options.Style && bannerConfig.Value) {
        setBannerText(bannerConfig.Value);
        setBannerStyle(bannerConfig.Options.Style);
        setInitialLoaded(true);
      }
    } catch (e) {
      console.error(e);
      setInitialLoaded(true);
    }
  }, [])
  React.useEffect(() => {
    setRefreshBanner(false);
    setTimeout(() => setRefreshBanner(true), 0);
  }, [bannerText, bannerStyle])
  
  return (
    <div>
      <h2 className={classes.title}>Configure Site Front Page Banner</h2>
      {!initialLoaded && (<CircularProgress />)}
      {initialLoaded && (
        <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
        {refreshBanner && (<SectionBanner text={bannerText} color={bannerStyle} section2/>)}
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
        <CustomInput
                  labelText="Banner Text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: bannerText,
                    onChange: (e) => {setBannerText(e.target.value)},
                  }}
                />
                <p>Entering 'disabled' as the text will disable the banner.</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
            <FormControl fullWidth className={basicClasses.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={basicClasses.selectLabel}
              >
                Banner Style
              </InputLabel>
              <Select
                MenuProps={{
                  className: basicClasses.selectMenu,
                }}
                classes={{
                  select: basicClasses.select,
                }}
                value={bannerStyle}
                onChange={(e) => setBannerStyle(e.target.value)}
              >
                {bannerStyleList.map((style) => {
                  return (
                    <MenuItem
                    key={style}
                  classes={{
                    root: basicClasses.selectMenuItem,
                  }}
                  value={style}
                >
                  {style[0].toUpperCase() + style.slice(1)}
                </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </GridItem>
          <Button round color="primary" disabled={submitted} onClick={handleBannerSubmission}>
            Submit Banner
          </Button>
      </GridContainer>
      {notify && (<SnackbarContent 
                message={notify.text}
                color={notify.color}
                icon={notify.color === 'success' ? Check : 'info_outline'}
                />)}
      </div>
      )}
    </div>
  );
}
